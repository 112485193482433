require('./bootstrap')


import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import { AssetDatabase, initDatabase } from './utils/AssetDatabase.js'

import { AudioSampler } from './utils/AudioSampler.js'
import eventUploader from './utils/EventUploader.js'
console.log("Starting App.");

const app = createApp( App )
//app.use( Vuex )
app.use( router );
app.use( store );

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.MIX_APP_URL;
console.log( 'App mounted on: ' + process.env.MIX_APP_URL );

app.config.globalProperties.$axios = axios;
initDatabase().then( () => {

    app.config.globalProperties.$assetDatabase = AssetDatabase;
    AudioSampler.setup();
    app.config.globalProperties.$audioSampler = AudioSampler;

    // Convenient shortcut for fast activity storage access.
    app.config.globalProperties.$activityStore = (activity) => {
        return {
            data: activity == null ? {} : activity.$store.getters['activities/storage'](activity),
            store: (dataToStore) => {
                if ( ! activity.$store.getters['auth/authenticated'] )
                    return;
                activity.$store.commit('activities/addData', {
                    activityName: activity.$options.name,
                    userId: activity.$store.getters['auth/user'].id,
                    data: dataToStore } );
            }
        }; };
    app.config.globalProperties.$event = (activity) => {
        return {
            data: activity.$store.getters['events/storage'],
            new: ( type, info = {} ) => {
                if ( !activity.$store.getters['auth/user'] ) return;
                activity.$store.commit('events/addEvent', _.merge( {
                            activity: activity.$options.name ? activity.$options.name : 'app',
                            user_id: activity.$store.getters['auth/user'].id
                        }, {
                            type:type,
                            info:info
                        } )
                    )
                }
        }; };

    app.config.globalProperties.$eventUplaoder = eventUploader( app );

    app.mount('#app')
} )

