export default {
    namespaced: true,

    state: {
        numberOfDismissals: 0,
    },

    getters: {
        numberOfDismissals: (state, getters, rootState) => {
            if ( !state )
                return 0;
            return state.numberOfDismissals;
        },
    },
    mutations: {
        increaseDismissals ( state ) {
            state.numberOfDismissals++;
        },
        resetDismissals ( state ) {
            if ( !state )
                return;
            state.numberOfDismissals = 0;
        },
    },
    actions: {
        async increaseDismissals( context ) {
            console.dir( context );
            context.commit( 'increaseDismissals' );
        }
    }
}
