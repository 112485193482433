import axios from "axios";
import _ from "lodash";

function findOrCreate( array, userId, activityName ) {
    let result = array.find( (element) => {
        return element.activityName === activityName && element.userId === userId; } );
    if ( !result ) {
        result = { activityName: activityName, userId: userId };
        array.push( result );
    }
    return result;
}

function getCurrentUserId( rootState ) {
    if ( !rootState.auth.authenticated )
        return 0;
    return rootState.auth.user.id;
}

export default {
    namespaced: true,

    state: {
        activityStorage: [
        ],
    },

    getters: {
        storage: (state, getters, rootState) => (activity) => {
            var currentUserId = getCurrentUserId( rootState );
            var activityName = activity.$options.name;
            let activityStorage = findOrCreate( state.activityStorage, currentUserId, activityName );
            return activityStorage;
        },

        details: (state, getters, rootState) => (activityName) => {
            var currentUserId = getCurrentUserId( rootState );
            let activityStorage = findOrCreate( state.activityStorage, currentUserId, activityName );
            return activityStorage;
        },
    },
    mutations: {
        addData ( state, payload ) {
            var currentUserId = payload.userId;
            var activityName = payload.activityName;
            var newData = payload.data;

            let storedData = findOrCreate( state.activityStorage, currentUserId, activityName );
            // NOTE: this will NOT override an array! this will only override a hole array!
            // but rather override only the values of the old array with the values of the new array.
            // that means, if your old array is longer than your new array, there will be entries
            // from the old array left over.
            _.merge( storedData, newData );
        },
        clear ( state, payload ) {
            state.activityStorage = [];
        },
    },
    actions: {
        async pullState( context ) {
            await axios.get( '/sanctum/csrf-cookie' ).then( async (response) => {
                await axios.get('/api/state' ).then( async (response) => {
                    console.log( response.data );
                    let data = response.data;
                    if ( !Array.isArray( data ) || data.length == 0 )
                        return;

                    response.data.forEach( function( element ) {
                        const { activityName, userId, ...data } = element;
                        context.commit('addData', {
                            userId: userId,
                            activityName: activityName,
                            data: data
                        } );
                    } );
                } );
            } );
        },
        pushState( context ) {
            let currentUserId = getCurrentUserId( context.rootState )
            let pushData = context.state.activityStorage.filter( (element) => {
                return element.userId === currentUserId; } );

            axios.get( '/sanctum/csrf-cookie' ).then( (response) => {
                axios.post('/api/state', pushData ).then( (response) => {
                    //console.dir( response );
                } )
            } );
        },
    }
}
