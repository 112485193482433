<template>
  <div class="bg-b">
    <div class="notification">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Notification',
    emits: [
        'goahead',
    ],
    props: {
        message: {
            default: 'Achtung',
            type: String
        },
        seconds: {
            default: 3,
            type: Number
        }
    },
    mounted () {
        setTimeout(() => {this.$emit('goahead')}, this.seconds*1000)
    },
    methods: {

    },
})
</script>

<style scoped>
    .bg-b {
        background-color: rgba(0, 0, 0, 0.5);
        z-index:1005;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notification{
        font-size:30px;
        color: black;
        width:90%;
        max-width: 500px;
        background-color: white;
        border: 2px solid lightgray;
        text-align: center;
        padding: 20px;
    }

</style>
