import { resolveTransitionHooks } from "@vue/runtime-core";
import { delay } from "./utils";


class EventUploader {
    constructor( app ) {
        this.app = app;
        this.axios = () => { return this.app.config.globalProperties.$axios };
        this.store = () => { return this.app.config.globalProperties.$store };
        this.isUploading = false;
    }

    queueSize() {
        return this.store().getters['events/storage'].slice().length;
    }

    async upload() {
        if ( this.isUploading )
            return Promise.resolve();
        this.isUploading = true;
        console.log( 'Trying to upload events. ');
        var events = this.store().getters['events/storage'].slice();

        var offline = false;
        if ( events.length )
            await this.axios().get('/sanctum/csrf-cookie').catch( () => {
                offline = true;
                return Promise.resolve()
            });
        if ( offline ) {
            return;
        }
        while( events.length > 0 ) {
            let event = events.shift();
            event.info = JSON.stringify( event.info );
            let self = this;
            await this.axios().post('api/event/new', event )
                .then( (response ) =>  {
                    console.log( response );
                    self.store().commit( 'events/removeEvent', event.event_uuid );
                    return Promise.resolve();
                } )
                .catch( error => {
                    console.log( error.response )
                    if ( error.response.status == 409 ) {
                        self.store().commit( 'events/removeEvent', event.event_uuid );
                        console.log("but this is fine, event " + event.event_uuid + "already uploaded");
                        return Promise.resolve();
                    } else if ( error.response.status == 429 ) {
                        // Too many requests, give it a break
                        events.unshift( event );
                        return delay( 60 * 1000 );
                    } else {
                        console.log( error );
                        console.log( error.response );
                        return Promise.resolve();
                    }
                } );
        }
        this.isUploading = false;
        return Promise.resolve();
    }
}

export default (axios) => new EventUploader( axios );
