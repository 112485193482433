<template>
    <div class="row">
        <template v-if="intro">
            <div
                class="col-12"
                >
                <Intro @end-intro="intro=false" />
            </div>
        </template>
        <template v-else-if="showVideo">
            <VideoScreen :videoSrc="videoBreakSrc" @ended="videoEnded" @error="videoEnded"/>
        </template>
        <template v-else>
            <div class="col-11" style="height: 95%">
                <component id="activity" class="centered"
                    :is="activityClass"
                    :key="nRefreshs"
                    :nRefreshs="nRefreshs"
                    :videoBreakSrc="videoBreakSrc"
                    @completed="activityCompleted"
                    @canceled="activityCanceled"
                    @restart="activityRestart" />
            </div>
            <div class="sideMenu">
                <SideMenu id="sideMenu" @next="activityStopped" @start="go2Dashboard" />
            </div>
        </template>
    </div>
</template>

<script>
import VideoScreen from '../components/Video'
import Intro from "../components/Intro"
import SideMenu from "../components/SideMenu"
import CognitiveActivity from "./CognitiveActivity"
import RelaxActivity from "./RelaxActivity"
import Cycler from '../utils/Cycler'
import eventValues from '../values/eventValues'
import { TimerManager } from '../utils/TimerManager'
import { MINUTE } from '../values/times'

export default {
    name: "Activity",
    components : {
        Intro,
        VideoScreen,
        SideMenu,
        RelaxActivity,
        CognitiveActivity
    },
    data() { return {
        intro: true,
        nRefreshs: 0,
        show: false,
        currentVideoBreak: 0,
        nVideoBreaks: 5,
        kogAkTimer: null,
        forceEnAk: false,
        showVideo: false,
        cancelConter: 0,
        enAks: new Cycler(['relaxation', 'movie', 'sing'], this.akPlaceholder),
        kogAks: new Cycler(['memory', 'balloon', 'recognition', 'cats', 'puzzle', 'wordsalad'], this.akPlaceholder),
        akPlaceholder: null,
        videoBreakSrc: '',
        };
    },
    computed: {
        activityClass() {
            return this.isEnAk() ? 'RelaxActivity' : 'CognitiveActivity';
        },
    },
    mounted() {
        this.loadConfig();
        this.storeConfig();
        this.loadBreakVideoAsset();
        if ( !this.isEnAk() )
            this.startForcedRelaxationTimer();
    },
    beforeUnmount() {
        this.stopTimer();
        this.storeConfig();
    },
    methods : {
        getOtherActivityData( name ) {
            var details = this.$store.getters['activities/details'](name);
            return details;
        },
        isEnAk() {
            return this.getAkIndex(this.enAks) != -1 ? true : false;
        },
        getAkIndex(arr) {
            return arr.indexOf(this.$route.params.name);
        },
        getNextKogAkt() {
            this.checkWordsalad();
            this.startForcedRelaxationTimer();
            this.switchCatPiano();
            return this.kogAks.next();
        },
        getNextEnAkt() {
            this.stopTimer()
            this.forceEnAk = false;
            return this.enAks.next();
        },
        go2Akt( activity ) {
            this.$router.push({path: '/activity/' + activity});
            setTimeout(() => {this.intro = true}, 10);
        },
        go2Dashboard(){
            this.$router.push({path: '/dashboard'});
        },
        async activityStopped(){
            // stopped activity by side menu
            if ( await this.handleForcedRelaxation() )
                return;

            console.log( "after handle forced timer.");
            let nextAkt = this.getNextKogAkt();
            this.go2Akt( nextAkt );
        },
        async activityCanceled() {
            // excludes current activity

            if ( this.isEnAk() ) {
                // should never happen
                this.go2Akt(this.getNextEnAkt());
                return;
            }

            this.cancelConter++
            this.kogAks.replace(this.$route.params.name, this.akPlaceholder) //???

            // if two activity canceled force enAk
            if (this.cancelConter == 2){
                this.cancelConter = 0
                this.forceEnAk = true
            }

            if ( await this.handleForcedRelaxation() )
                return;

            // give a break after cancel
            await this.playBreakVideo();
            this.go2Akt( this.getNextKogAkt() );
        },
        async activityCompleted(){
            // start next kogAkt
            if ( await this.handleForcedRelaxation() ) {
                return;
            }
            if ( !this.isEnAk() ) {
                await this.playBreakVideo();
            }
            this.go2Akt( this.getNextKogAkt() );
        },
        async activityRestart(){
            // If conditions for forced break are met, ignore the restart
            // and got to relax activity instead
            if ( await this.handleForcedRelaxation() ) {
                // return statement is important to prevent further execution
                return;
            }

            //'Key-Changing': Aktualisierung des properties 'currentComponentKey'
            //führt zur Zertsörung der aktuellen Component und zu einer automatischen
            //Neurenderung durch Vue selbst
            this.nRefreshs += 1;
        },
        async handleForcedRelaxation() {
            let forcedRelaxation = this.forceEnAk;
            if ( forcedRelaxation ) {
                // play voice
                await this.$audioSampler.playSound('relax')
                this.go2Akt( this.getNextEnAkt() );
                // return statement is important to prevent further execution
            }
            return forcedRelaxation;
        },
        async loadBreakVideoAsset() {
            this.videoBreakSrc = await this.$assetDatabase.getVideoUrl( "assets/videos/breaks/video" + (this.currentVideoBreak+1) + ".mp4" );
        },
        playBreakVideo() {
            // Each kogAk is followed by a short 2 min video (alternating videos from library).

            // Start video
            this.showVideo = true
            return new Promise( res => { this.videoEnded = res; }).finally(() => {
                // Close video screen
                this.showVideo = false;
                // Set Break video to next index
                this.currentVideoBreak = (this.currentVideoBreak+1) % this.nVideoBreaks;
            });

        },
        checkWordsalad() {
            let memoryData = this.getOtherActivityData('ca-memory');
            let recogData = this.getOtherActivityData('ca-recognition');
            if (recogData.hasOwnProperty('difficulty') && memoryData.hasOwnProperty('difficulty') &&
                memoryData.difficulty.level > 5 && recogData.difficulty.level > 5) {
                    // replace wordsalad before memory in cycler
                    this.kogAks.replace('memory', 'wordsalad', -1)
                    this.storeConfig();
            }
            else {
                this.kogAks.replace('wordsalad', this.akPlaceholder)
                this.storeConfig();
            }
        },
        switchCatPiano() {
            let currAk = this.$route.params.name
            if (currAk == 'cats')
                this.kogAks.replace('cats', 'piano')
            else if(currAk == 'piano')
                this.kogAks.replace('piano', 'cats')
        },
        loadConfig(){
            // load storage
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('enAks') )
                this.enAks = new Cycler(storedData.enAks, this.akPlaceholder);
            if ( storedData.hasOwnProperty('kogAks') )
                this.kogAks = new Cycler(storedData.kogAks, this.akPlaceholder);

            // corrected activity lists (necessary after dashboard)
            if (this.isEnAk()) {
                this.enAks.goTo(this.$route.params.name)
            }
            else {
                this.kogAks.goTo(this.$route.params.name)
            }
        },
        storeConfig(){
            this.$activityStore( this ).store( {
                enAks: this.enAks.get(),
                kogAks: this.kogAks.get()
            } );
        },
        startForcedRelaxationTimer() {
            console.log("Starting relax timer.");
            TimerManager.registerTimeout( 'relaxTimer', () => {
                    console.log("relax timer fired");
                    this.$event(this).new( eventValues.ACTIVITY_INFO, { message: "30 minuten forced relaxation." } );
                    this.forceEnAk = true
                }, 30 * MINUTE, false );
        },
        stopTimer() {
            TimerManager.unregisterTimer( 'relaxTimer' );
            this.forceEnAk = false;
        },
        videoEnded() {
            // placeholder to wait for end of video
        },
    }
}
</script>

<style>
#activity {
    width: 90%;
    height: 100%;
}

#sideMenu {
    position: fixed;
    width: 15%;
    min-width: 6em;
    max-width: 9em;
    height: 100%;
    right:0%;
    top: 0%;
    padding-right: 1.5em;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    z-index: 900;
}

.centered {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
}
</style>
