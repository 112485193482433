<template>
    <div :v-show=ready class="bg-w">
        <div class="output">
            Glückwunsch!
        </div>
        <div class="result">
            <img class="bird" :style="animationDuration" src="/assets/win/bird.gif">
        </div>
        <div class="result">
            <img class="bird" :style="animationDuration" src="/assets/win/bird.gif">
        </div>
        <div class="result">
            <img class="bird" :style="animationDuration" src="/assets/win/bird.gif" style="transform: scaleY(-1)">
        </div>
        <div class="result">
            <img class="bird" :style="animationDuration" src="/assets/win/bird.gif" style="transform: scaleY(-1)">
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Meanderer from "../utils/Meanderer"

    const pathes = [
        "M-25.458 81.595A231.715 231.715 0 0 1-7.37 69.027c6.968-4.366 14.318-8.418 22.376-10.055 6.885-1.399 14.087-.965 20.87.864 6.784 1.83 13.158 5.03 18.92 9.05 11.523 8.04 20.488 19.19 28.704 30.588 2.117 2.937 4.196 5.903 6.35 8.813 10.687 14.434 23.733 27.851 40.332 34.711 15.324 6.334 32.872 6.551 48.743 1.75 16.34-4.941 30.779-14.894 43.357-26.437 5.817-5.338 11.295-11.041 17.19-16.293 17.949-15.993 40.344-27.82 64.303-29.79a88.43 88.43 0 0 1 34.247 3.95",
        "M-23.862 106.88a102.481 102.481 0 0 1 32.086 15.44c6.708 4.827 12.82 10.458 19.672 15.079 6.851 4.62 14.69 8.265 22.953 8.412 9.258.165 18.11-4.092 25.462-9.722 7.352-5.629 13.517-12.63 20.19-19.05 12.028-11.575 25.947-21.404 41.538-27.372 15.59-5.968 32.932-7.929 49.165-4.033 17.724 4.254 33.087 15.067 47.498 26.229 14.41 11.16 28.627 23.062 45.433 30.118 14.255 5.985 30.06 8.234 45.419 6.463",
        "M317.341 132.69c-6.936 7.302-16.063 12.495-25.884 14.729-9.82 2.233-20.296 1.497-29.708-2.087-10.114-3.85-18.81-10.84-25.935-18.986-2.483-2.84-4.794-5.827-7.315-8.633-11.331-12.615-26.696-21.222-42.96-26.02-16.263-4.796-33.432-5.937-50.37-5.146-6.848.32-13.716.956-20.355 2.663-6.639 1.706-13.07 4.522-18.38 8.858-3.412 2.786-6.33 6.17-9.942 8.693-4.077 2.849-8.867 4.485-13.2 6.927-6.062 3.417-11.109 8.336-16.491 12.747-9.399 7.703-20.24 14.051-32.186 16.279-4.564.851-9.222 1.09-13.864 1.107a147.312 147.312 0 0 1-38.337-4.937",
        "M310.492 95.285c-11.216-9.047-26.55-12.765-40.664-9.86-10.945 2.252-20.794 8.172-29.726 14.887-8.931 6.715-17.19 14.318-26.399 20.647-28.303 19.454-65.708 25.575-98.317 14.798-19.861-6.564-37.298-18.792-54.063-31.3-6.496-4.847-12.977-9.784-20.15-13.556-11.45-6.022-24.281-8.875-37.039-11.027-2.723-.46-5.463-.89-8.225-.928-2.761-.037-5.562.333-8.1 1.422-1.435.615-2.764 1.449-4.166 2.135-1.402.685-2.919 1.23-4.48 1.201a6.456 6.456 0 0 1-4.62-2.11"
    ]

function initPath() {
    const height = 210;
    const width = 297;

    for (var i=0; i < pathes.length; i++) {
        let container = $(".result")[i];
        let responsive = new Meanderer({ path:pathes[i], height, width });
        let setPath = () => {
            let scaledPath = responsive.generatePath(
                container.offsetWidth,
                container.offsetHeight
            );
            container.style.setProperty("--path", `"${scaledPath}"`);
        };

        let SizeObserver = new ResizeObserver(setPath);
        SizeObserver.observe(container);
    }
};

function initSize() {
    let ratio = 0.10;
    let size = window.innerWidth * ratio;
    $(".bird").each((i, el) => {
        $(el).css("width", size + "px");
        $(el).css("height" ,size + "px");
    });
}

export default defineComponent({
    name: "win-animation",
    emits: [
        'back',
        'restart'
    ],
    data(){
        return {
            oldCopyrights: "none",
            backgorundImgUrl: '',
            ready: false,
        }
    },
    computed: {
        animationDuration() {
            return 'animation-duration: ' + this.duration + 's';
        }
    },
    props: {
        duration: Number
    },
    mounted() {
        setTimeout(()=>{$('#bird-sound')[0].play()}, Math.max(0, (this.duration-2)*500));
        this.oldCopyrights = $('#copyright')[0].innerHTML;
        $('#copyright')[0].innerHTML +=
        '<a href="https://tenor.com/view/bird-blue-wings-flying-gif-17748964">Bird Blue GIF</a>from <a href="https://tenor.com/search/bird-gifs">Bird GIFs</a>';


        this.loadBackground().then( () => {
            initPath();
            initSize();
            this.ready = true;
        } );
    },
    beforeUnmount() {
        $('#copyright')[0].innerHTML = this.oldCopyrights;
    },
    methods: {
        async loadBackground() {
            this.backgorundImgUrl = await this.$assetDatabase.getPngUrl('assets/win/ellipse.png');
            await $('.output').css( 'background-image', "url('" + this.backgorundImgUrl + "')" );
        }
    }

})
</script>

<style scoped>
    .bg-w {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .output{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 10px;
        z-index:1000;
        color: var(--bs-yellow);
        font-size: 50px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .result {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .bird{
        position: absolute;
        top: 0%;
        left: 0%;
        z-index: 1001;
        animation-name: bird-fly;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        offset-path: path(var(--path));
    }

   .bounce-enter-active {
    	animation: bounce-in 1s;
   }
   .bounce-leave-active {
    	animation: bounce-in 1s reverse;
   }

   @keyframes bounce-in {
       0%  { transform: scale(0.25);}
       25%  { transform: scale(0.5);}
       75%  { transform: scale(0.75);}
       100% { transform: scale(1);}
   }

    @keyframes bird-fly {
        from {offset-distance: 0%;}
        to {offset-distance: 100%;}
    }
</style>
