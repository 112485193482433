export default {
    SESSION_START: 'SESSION_START',
    SESSION_END: 'SESSION_END',
    ACTIVITY_START: 'ACTIVITY_START',
    ACTIVITY_END: 'ACTIVITY_END',
    ACTIVITY_INFO: 'ACTIVITY_INFO',

    PERFORMED: 0,
    CANCELED: 1,
    CORRECT: 1,
    NOT_CORRECT: 0,
    REASON_INACTIVE: 'inactive',
    REASON_CANCEL: 'cancel',
    REASON_FAIL: 'fail',
    REASON_WIN: 'win'
}
