<template>
    <div>
        <VideoScreen v-if="showVid" :videoSrc="videoSrc" @ended="vEnd" @error="vErr" />
        <div v-else class="col d-flex justify-content-around" >
            <button class="btn btn-primary btn-lg text-white" @click="retry">Nochmal</button>
            <button class="btn btn-primary btn-lg text-white" @click="newActivity">Neue Aktivität</button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import VideoScreen from '../components/Video'
import { createAutoplayingVideoElementFromBlob, startVideoOrShowControls } from '../utils/utils';
import eventValues from '../values/eventValues.js';

export default defineComponent({
    name: "RelaxActivity",
    components : {
        VideoScreen
    },
    emits: [
        'completed',
        'canceled',
        'restart'
    ],
    data() {
        return {
            showVid: true,
            states: {
                'movie': {'index': 0, 'length': 6},
                'sing': {'index': 0, 'length': 20},
                'relaxation': {'index': 0, 'length': 3}
            },
            videoSrc: undefined,
        };
    },
    computed : {
    },
    mounted() {
        this.loadConfig();

        this.showVid = true;
        var assetName = "assets/videos/" + this.$route.params.name + "/video" + (this.getState().index+1) + ".mp4";
        this.loadVideo( assetName );

        if (this.$route.params.name == 'sing')
            document.getElementById('copyright').innerHTML = "Song nicht für kommerziellen Zweck verwendbar";
        this.$event(this).new( eventValues.ACTIVITY_START, { type: this.$route.params.name, video: this.getState().index+1 } );
    },
    beforeUnmount() {
        this.storeConfig();
        document.getElementById('copyright').innerHTML = ''
    },
    methods: {
        async loadVideo( video ) {
            this.videoSrc = await this.$assetDatabase.getVideoUrl( video );
            startVideoOrShowControls();
        },
        vEnd () {
            if (this.$route.params.name == 'sing'){
                this.showVid = false;
            }
            else
                this.newActivity();
        },
        vErr() {
            this.$emit('canceled');
        },
        newActivity () {
            let index = (this.getState().index + 1) % this.getState().length;
            this.states[this.$route.params.name].index = index;

            // video watched successfully.
            this.$event( this ).new( eventValues.ACTIVITY_END, {
                correct: eventValues.CORRECT,
                reason: eventValues.REASON_WIN,
                performed: eventValues.PERFORMED
                } );
            this.$emit('completed');
        },
        retry() {
            // video watched successfully and wanna rewatch.
            this.$event( this ).new( eventValues.ACTIVITY_END, {
                correct: eventValues.CORRECT,
                reason: eventValues.REASON_WIN,
                performed: eventValues.PERFORMED
                } );
            this.$emit('restart');
        },
        getState(){
            return this.states[this.$route.params.name];
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('states') )
                this.states = storedData.states;
        },
        storeConfig() {
            this.$activityStore( this ).store( {
                states: this.states
            } );
        },
    }
})
</script>
