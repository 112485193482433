import axios from 'axios'



async function getCsrfCookie() {
    return axios.get( '/sanctum/csrf-cookie' );
}
async function login( credentials ) {
    return axios.post('/api/login', credentials );
}

async function getAuthUser() {
    return axios.get( '/api/user' );
}

export default {
    namespaced: true,

    state: {
        authenticated: false,
        user: null,
    },

    getters: {
        authenticated (state) {
            return state.authenticated
        },

        user (state) {
            return state.user
        },
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },

        SET_USER (state, value) {
            state.user = value
        },
    },

    actions: {
        async signIn ( context, credentials) {
            if ( !credentials )
                return;

            await getCsrfCookie();
            await login( credentials )
            await getAuthUser().then( async (response) => {
                await context.dispatch( 'me', response.data );
            } ).catch( (error) => {
                // Errors might happen if the user is not logged in.
                context.dispatch( 'me', null );
            } )

            return context.getters['user'];
        },
        signOut ( context ) {
            return context.dispatch('me', null );
        },
        me ( context, user) {
            if ( user ) {
                context.commit( 'SET_AUTHENTICATED', true );
                context.commit( 'SET_USER', user );
                return Promise.resolve();
            } else {
                context.commit( 'SET_AUTHENTICATED', false );
                context.commit( 'SET_USER', null );
                return Promise.resolve();
            }
        },
        async updateUser( context ) {
            if ( ! context.getters['user'] ) {
                return null;
            }
            await getCsrfCookie().then( async (response) => {
                await axios.get( '/api/user' ).then( (response) => {
                    // Check if session identifier is still the same.
                    let lastKnownUser = context.getters['user'];
                    let newSessionId = response.data.session_identifier;
                    if ( ( lastKnownUser != null ) &&
                         ( lastKnownUser.session_identifier != newSessionId ) )
                        context.dispatch('signOut');
                    else
                        context.dispatch( 'me', response.data );
                } )
            } )
        },

    }
}
