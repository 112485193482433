import _ from "lodash";
import { uuid } from 'vue-uuid';
import axios from 'axios'


async function getCsrfCookie() {
    return axios.get( '/sanctum/csrf-cookie' );
}
function getCurrentDateTimeinMySqlFormat() {
    let date = new Date();
    return date.toISOString().slice(0, 19).replace('T', ' ');
}
export default {
    namespaced: true,

    state: {
        eventStorage: [
        ],
    },

    getters: {
        storage: (state, getters, rootState) => {
            if ( !state )
                return [];
            return state.eventStorage;
        },
    },
    mutations: {
        addEvent ( state, payload ) {
            if ( !state )
                return;
            payload.event_uuid = uuid.v4();
            payload.timestamp = getCurrentDateTimeinMySqlFormat();
            state.eventStorage.push( payload );
        },
        removeEvent ( state, uuid ) {
            if ( !state )
                return;
            var eventIndex = state.eventStorage.findIndex( (event) => { return event.event_uuid == uuid } );
            if ( eventIndex !== -1 ) {
                state.eventStorage.splice( eventIndex, 1 );
            }
        },
        clear ( state ) {
            console.log( "clear Events");
            if ( !state )
                return;
            state.eventStorage = [];
        },
    },
    actions: {
        async push( context ) {
            let events = context.getters['storage'].slice();

            console.log( 'Trying to upload events. ');

            let offline = false;
            if ( events.length )
                await getCsrfCookie().catch( () => {
                    offline = true;
                });
            if ( offline ) {
                return;
            }
            // We are online and we should be able to upload events
            await axios.post( 'api/event/batch', events ).then( ( response ) => {
                console.log( response );
                events.forEach( (event ) => {
                    context.commit( 'removeEvent', event.event_uuid );
                } );
            } ).catch( (error) => {
                console.log( error );
            } );
        }
    }
}
