<template>
    <div class="memory">
        <div class="memory-board">
            <vue-flip :width=cardsize :height=cardsize v-for="(item, $index) in deck" :key="$index" class="memorycard" v-model="item.flipped" @click="handleClick(item)">
                <template v-slot:front>
                    <div :class="{ 'found': item.found }" class="innerfront">
                        {{ item.found ? '✔️' : '?' }}
                    </div>
                </template>
                <template v-slot:back>
                    <img :src="item.precomputedAssetUrl()" />
                </template>
            </vue-flip>
        </div>
        <audio id="flipSound"></audio>
    </div>
</template>

<script>
import {getUniqueRandomIndices, registerIdleTimers, secondsSince, shuffleArray, unregisterIdleTimers} from '../utils/utils.js'
import VueFlip from 'vue-flip'
import { defineComponent } from 'vue'
import eventValues from '../values/eventValues.js';
import { AssetDatabase } from '../utils/AssetDatabase.js';
class Card {
    constructor( value ) {
        this._value = value;
        this._flipped = false;
        this._found = false;
        this._assetUrl = "";
    }
    get flipped() {
        return this._flipped;
    }
    get found() {
        return this._found;
    }
    get value() {
        return this._value;
    }

    set flipped( f ) {
        this._flipped = f;
        if ( this._flipped )
          document.getElementById('flipSound').play();
    }
    set found( f ) {
        this._found = f;
    }
    set value( v ) {
        this._value = v;
    }

    async computeAssetUrl() {
        const baseUrl = "assets/memory/tiles/";
        const assetExtension = ".png";
        this._assetUrl = await AssetDatabase.getPngUrl( baseUrl + this.value + assetExtension);
    }
    precomputedAssetUrl() {
        return this._assetUrl;
    }

};

export default defineComponent({
    name: 'ca-memory',
    emits: [
        'end-win',
        // after a successful round, the game is restarted
        // with the same difficulty.

        'end-level-up',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'end-completed',
        // after beating the final difficulty level.

        'end-fail',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'next',
        // start next activity,

        'exclude',
        // if failed consecutively, exclude the activity
        // from further activities

        'long-idle',
        // Emitted when the player was ideling for at least 3 minutes.
    ],
    components: {
        VueFlip
    },
    data () {
        return {
            deck: [],
            cardSelected: [],
            difficulty: {level: 3, pairs:4, rows:2, cols:4},
            difficulty_config: [
                {level: 1, pairs:2, rows:2, cols:2},
                {level: 2, pairs:3, rows:2, cols:3},
                {level: 3, pairs:4, rows:2, cols:4},
                {level: 4, pairs:5, rows:2, cols:5},
                {level: 5, pairs:6, rows:3, cols:4},
                {level: 6, pairs:8, rows:4, cols:4},
                {level: 7, pairs:10, rows:3, cols:4},
                {level: 8, pairs:12, rows:5, cols:5},
                {level: 9, pairs:15, rows:5, cols:6},
            ],
            idleTimer: null,
            longIdleTimer: null,
            consecutiveWins : 0,
            consecutiveFails : 0,
            consecutiveMissClicks : 0,
            cardsize: "100px",
            startDate: new Date(),
        }
    },
    mounted () {
        this.loadConfig();

        if ( !this.restart )
            this.consecutiveWins = 0;

        this.initializeBoard().then( this.startGame )
    },
    beforeUnmount() {

        window.removeEventListener('resize', this.updateCardSize );
        this.stopIdleTimer();
        this.storeConfig();
    },
    props: {
        restart: Boolean
    },
    methods: {
        getDifficultyByLevel( level ) {
            if ( !level )
                level = 3;
            if ( level < 1 )
                level = 1;
            if ( level > 9 )
                level = 9;
            return this.difficulty_config.find( diff => diff.level == level );
        },
        atLeastOnePairWasFound() {
            if ( this.deck.find( card => card.found ) )
                return true;
            return false;
        },
        handleClick (card) {
            let self = this;
            this.restartIdleTimer();
            this.consecutiveMissClicks++;

            if ( !card.found && !card.flipped ) {
                card.flipped = true;
                this.cardSelected.push(card);
                while ( this.cardSelected.length > 2 ) {
                    var oldCard = this.cardSelected.shift();
                    oldCard.flipped = false;
                }
                if ( this.cardSelected.length === 2 ) {
                    let card1 = this.cardSelected[0];
                    let card2 = this.cardSelected[1];
                    if ( card1.value === card2.value ) {
                        self.$event( self ).new( eventValues.ACTIVITY_INFO, { found_pair: card1.value } );
                        setTimeout( () => {
                            card1.found = true;
                            card2.found = true;
                            card1.flipped = false;
                            card2.flipped = false;
                            self.checkForWin();
                        }, 1000 )
                        this.cardSelected = [];
                        this.consecutiveMissClicks = 0;
                    }
                }
            }
            // Handle failing
            if ( !this.atLeastOnePairWasFound() && this.consecutiveMissClicks > this.difficulty.pairs * 2.5 ) {
                this.abortGame()
            }
        },
        abortGame() {
            this.$event( this ).new( eventValues.ACTIVITY_END, {
                correct: eventValues.NOT_CORRECT,
                reason: eventValues.REASON_FAIL,
                performed: secondsSince( this.startDate ) ? eventValues.PERFORMED : eventValues.CANCELED
                } );

            this.consecutiveFails++;
            this.consecutiveWins = 0;
            if ( this.consecutiveFails >= 2 ) {
                this.$event( this ).new( eventValues.ACTIVITY_INFO, { exclude: true } );
                this.$emit('exclude');
                return;
            }
            this.levelDown();
            this.$emit('end-fail');
        },
        levelUp() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level + 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
        },
        levelDown() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level - 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
        },
        checkForWin() {
            if ( ! this.deck.find( card => !card.found ) ) {
                this.consecutiveWins++;
                this.consecutiveFails = 0;
                // if all cards have been found
                this.stopIdleTimer();
                // round is completed successfully.
                this.$event( this ).new( eventValues.ACTIVITY_END, {
                    correct: eventValues.CORRECT,
                    reason: eventValues.REASON_WIN,
                    performed: eventValues.PERFORMED
                    } );

                if ( this.consecutiveWins >= 5 && this.difficulty.level >= 9 ) {
                    this.consecutiveWins = 0;
                    this.$emit('end-completed');
                } else if ( this.consecutiveWins >= 5 ) {
                    this.levelUp();
                    this.$emit('end-level-up');
                } else {
                    this.$emit('end-win');
                }
            }
        },
        restartIdleTimer() {
            registerIdleTimers( this );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
            this.$audioSampler.stop();
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
            this.startDate = new Date();
        },
        storeConfig() {
            this.$activityStore( this ).store( {
                difficulty: this.difficulty,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails,
            } );
        },
        async initializeBoard() {
            $('#flipSound').attr('src', await AssetDatabase.getSoundUrl('assets/sounds/Card-flip-sound-effect.mp3'));

            var numberOfPairs = this.difficulty.pairs;
            var board = [];
            var maxNumberOfPairs = 102;
            let pairs = getUniqueRandomIndices( numberOfPairs, maxNumberOfPairs-1 )
            pairs.forEach( pairIndex => {
                board.push( new Card( pairIndex ) );
                board.push( new Card( pairIndex ) );
            } )
            for ( var i = 0; i < board.length; i++ ) {
                await board[i].computeAssetUrl();
            }
            this.updateCardSize();
            this.deck = shuffleArray( shuffleArray( board ) );

            window.addEventListener('resize', this.updateCardSize );
            //$('.memory-board').css('grid-template-columns', 'repeat(' + this.difficulty.cols + ', 1fr)');
        },
        updateCardSize() {
            // Compute Card size
            let width = $('#activity').outerWidth();
            let height = $('#activity').outerHeight();
            let desiredWidth = 0.8 * width / this.difficulty.cols;
            let desiredHeight = 0.8 * height / this.difficulty.rows;
            this.cardsize = '' + (desiredWidth < desiredHeight ? desiredWidth : desiredHeight) + 'px';
        },
        startGame() {
            this.$event( this ).new( eventValues.ACTIVITY_START, { level: this.difficulty.level, difficulty: this.difficulty, restart: this.restart } );

            this.restartIdleTimer();
        }

    }
})
</script>

<style>
    .front, .back {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .front {
        background-color: lightblue;
    }
    .back {
        background-color: blue;
    }
    .innerfront.found {
        background-color: green;
    }

    .memorycard:after {
        display: table;
    }
    .memorycard img {
        text-align: center;
        vertical-align: middle;
        width: 80%;
        height: 80%;
    }

    .memory-board {
        display: flex;
        flex-wrap: wrap;
    }
</style>
<style scoped>
  .memory {
    display: flex;
    flex-wrap: wrap;
  }
  h1 {
    width: 100%;
  }
  .memorycard {
    width: 120px;
    display: flex;
    margin: 10px;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    user-select: none;
    font-size: 4rem;
    align-items: stretch;
    justify-content: center;
  }
  .innerfront {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .front {
    width: 100%;
    height: 100%;
  }
</style>
