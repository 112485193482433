<template>
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col">
                <div class="alert alert-danger" role="alert" v-if="error !== null">
                    {{ error }}
                </div>

                <div class="card card-default">
                    <div class="card-header">
                        <h2 class="d-flex justify-content-center align-items-center">
                            <img src="https://dementia-web.spectelligence.com/assets/icons/icon-144x144.png" class="logo" alt="Dementia Web Logo"/>
                            Anmeldung
                        </h2>
                    </div>
                    <div class="card-body">
                        <form>
                        <label for="loginId" class="d-flex justify-content-center">
                        <p>Ihre Login ID</p>
                        </label>

                            <div class="d-flex row justify-content-center">
                               <div class="col">
                                  <input id="loginId" type="text" class="form-control" v-model="form.loginId" required autocomplete="username" >
                              </div>
                            </div><br>
                            <div class="d-flex justify-content-center">
                               <button id="btn-login" type="submit" class="btn btn-primary text-white" @click="handleSubmit">
                                        Jetzt Starten
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer">
                        <div v-if="showResetIdLink">
                            Wenn Sie Ihre Login Id vergessen haben, klicken Sie bitte <a href="https://dementia-web.spectelligence.com/user/forgot">hier</a> um eine neue zu erhalten.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { makeFullscreen } from '../utils/utils';
import eventValues from '../values/eventValues';

export default {
    data() {
        return {
            form: {
                loginId: '',
            },
            error: null,
            showResetIdLink : false,
          }
    },
    mounted () {
        //this.$store.commit('events/clear');
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn'
        }),
        async handleSubmit(e) {

            if (this.form.loginId.length > 0) {
                e.preventDefault()
                //await this.$store.dispatch('auth/signIn', this.form );

                this.signIn( this.form ).then( async (response) => {
                    if( !response ) {
                        this.error = "Nutzer nicht vorhanden.";
                        this.showResetIdLink = true;
                    }

                    await this.$store.dispatch('activities/pullState');

                    makeFullscreen();
                    this.$router.replace( { name: 'dashboard' } )
                }).catch( (reason) => {
                    this.error = "Fehler bei der Anmeldung. Bitte prüfen Sie Ihre Login Id."
                this.showResetIdLink = true;
                } );
            } else {
                this.showResetIdLink = true;
            }
        }
    }
}
</script>
