<template>
    <div id="videoScreenDiv" class="d-flex px-3">
        <div
            id="loadingContainer"
            v-if="loading"
            >

            <div id="loadingIndicator" class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <video id="videoElement" autoplay @ended="videoEnd" @click="togglePlay">
        </video>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { delay } from '../utils/utils';

export default defineComponent({
    data() {
        return {
            loading: true,
        }
    },
    props: {
        videoSrc: String
    },
    emits: [
        'ended',
        'error'
    ],
    methods: {
        videoErr() {
            this.$emit('error');
        },
        videoEnd() {
            this.$emit('ended');
        },
        setVideo( vid ) {
            if ( vid != undefined  && vid != null) {
                this.loading = true;
                delay( 1000 ).then( () => {
                    $('#videoElement').empty()
                    const source = document.createElement('source');
                    source.src = this.videoSrc;
                    source.type = 'video/mp4';
                    source.onerror = this.videoErr;
                    $('#videoElement').append(source)
                    $('#videoElement')[0].oncanplay = this.loadingComplete();
                } )
            }
        },
        loadingComplete() {
            this.loading = false;
        },
        togglePlay() {
            var video = $("#videoElement").get(0);
            if ( video.paused ) {
                video.play();
            } else {
                video.pause();
            }
        }
    },
    watch: {
        videoSrc: function() {
            this.setVideo( this.videoSrc );
        }
    },
    mounted() {
        this.setVideo( this.videoSrc );
    },

} );
</script>

<style>
#videoElement {
    width: 100%;
    max-height: 80vh;
}

#loadingContainer {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
}
</style>
