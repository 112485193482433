<template>
  <div class="recognition">
    <div class="row">
        <div class="d-flex align-items-center justify-content-center col-sm-6 col-12 mb-3 mb-sm-0">
            <div id="recognition-image">
            </div>
        </div>
        <div class="d-flex align-items-center col-sm-6 col-12">
            <div id="recognition-choices" class="container">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { createPromise, getRandomElement, registerIdleTimers, shuffleArray, unregisterIdleTimers } from '../utils/utils.js'
import eventValues from '../values/eventValues.js';
import { AssetDatabase } from '../utils/AssetDatabase.js';

class RecognitionObject {

    constructor( name, similar, _dissmilar, asset ) {
        this._name = name;
        this._similar = similar;
        this._dissmilar = _dissmilar;
        this._asset = asset;
    }

    name() {
        return this._name;
    }

    similar() {
        return this._similar;
    }
    dissimilar() {
        return this._dissmilar;
    }

    asset() {
        return this._asset;
    }


    imageAssetUrl() {
        const baseUrl = "assets/recognition/";
        return baseUrl + this.asset();
    }

    /**
     * @param difficulty {similar, distanced}
     */
    generateChoices( componentReference, similar_words, dissimilar_words ) {
        let choices = [];
        if ( similar_words > 0 ) {
            choices = choices.concat( shuffleArray( this.similar() ).slice( 0, similar_words ) );
        }
        if ( dissimilar_words > 0 ) {
            choices = choices.concat( shuffleArray( this.dissimilar() ).slice( 0, dissimilar_words ) );
        }
        choices = shuffleArray( choices );

        choices.push( this.name() )
        choices = shuffleArray( choices );
        choices = shuffleArray( choices );

        choices.forEach( choice => {
            $('#recognition-choices').append(
                '<button id="' + choice + '" class="m-1 m-md-3 btn btn-primary choiceButton"> ' + choice  + ' </button>'
            );
        } );
        $('.choiceButton').on( 'click', async function( event ) {
            await createPromise($('#clickAudio')[0]);
            componentReference.onClick( event.target.id );
        } );

    }
}
async function setImage( recogObject ) {
    let image = new Image();
    image.src = await AssetDatabase.getJpgUrl( recogObject.imageAssetUrl() );
    $( "#recognition-image" ).append( image );
}


export default defineComponent({
    name: 'ca-recognition',
    emits: [
        'end-win',
        // after a successful round, the game is restarted
        // with the same difficulty.

        'end-level-up',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'end-completed',
        // after beating the final difficulty level.

        'end-fail',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'next',
        // start next activity,

        'exclude',
        // if failed consecutively, exclude the activity
        // from further activities

        'long-idle',
        // Emitted when the player was ideling for at least 3 minutes.
    ],
    components: {
    },
    data () {
        return {
            my_object: null,
            dictionary: [],
            difficulty: { level: 2, similar_words: 0, dissimilar_words: 3 },
            difficulty_config: [
                { level: 1, similar_words: 0, dissimilar_words: 2 },
                { level: 2, similar_words: 0, dissimilar_words: 3 },
                { level: 3, similar_words: 3, dissimilar_words: 0 },
                { level: 4, similar_words: 0, dissimilar_words: 5 },
                { level: 5, similar_words: 4, dissimilar_words: 0 },
                { level: 6, similar_words: 0, dissimilar_words: 5 },
                { level: 7, similar_words: 5, dissimilar_words: 0 },
            ],
            consecutiveWins: 0,
            consecutiveMissClicks: 0,
            consecutiveFails: 0,
            idleTimer: null,
            longIdleTimer: null,
            lastWords: [],
        }
    },
    props: {
        restart: Boolean
    },
    mounted () {
        this.loadConfig();
        this.startIdleTimer();

        if ( !this.restart )
            this.consecutiveWins = 0;

        this.$axios.get('/assets/recognition/words.json').then( ( response ) => {
            this.dictionary = response.data;
            this.setupGame();
        } );
    },
    beforeUnmount() {
        this.stopIdleTimer();
        this.storeConfig();
    },
    methods: {
        setupGame() {

            let o = getRandomElement( this.dictionary );
            while( this.lastWords.includes( o.name ) ) {
                o = getRandomElement( this.dictionary );
            }
            // put the current word to the front of the array
            this.lastWords.unshift( o.name );
            // limit the array length
            while( this.lastWords.length > 10 ) {
                this.lastWords.pop();
            };

            this.my_object = new RecognitionObject( o.name, o.similar_words, o.dissimilar_words, o.asset );

            setImage( this.my_object );
            this.my_object.generateChoices(
                this,
                this.difficulty.similar_words,
                this.difficulty.dissimilar_words
                );

            this.$event( this ).new( eventValues.ACTIVITY_START, {
                level: this.difficulty.level,
                difficulty: this.difficulty,
                restart: this.restart,
                object:this.my_object.name() } );
        },
        onClick( choice ) {
            this.startIdleTimer();
            this.$event( this ).new( eventValues.ACTIVITY_INFO, {
                object: this.my_object.name(),
                choice: choice } );

            if ( choice == this.my_object.name() ) {
                this.$event( this ).new( eventValues.ACTIVITY_END, {
                    correct: eventValues.CORRECT,
                    reason: eventValues.REASON_WIN,
                    performed: eventValues.PERFORMED
                    } );

                this.consecutiveMissClicks = 0;
                this.consecutiveFails = 0;
                this.consecutiveWins++;

                if ( this.consecutiveWins >= 10 && this.difficulty.level == 7 ) {
                    this.consecutiveWins = 0;
                    this.$emit('end-completed');
                }
                else if ( this.consecutiveWins >= 10 ) {
                    this.levelUp();
                    this.$emit('end-level-up');
                } else {
                    this.$emit('end-win');
                }
            } else {
                this.consecutiveMissClicks++
                if ( this.consecutiveMissClicks >= 4 ) {
                    this.consecutiveFails++;
                    if ( this.consecutiveFails == 2 ) {
                        this.levelDown();
                        this.$emit('end-fail');
                    } else if ( this.consecutiveFails >= 3 ) {
                        this.$emit('exclude');
                    } else {
                        this.$emit('end-fail');
                    }
                }
            }
        },
        startIdleTimer() {
            registerIdleTimers( this );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
            if ( storedData.hasOwnProperty('lastWords') )
                this.lastWords = storedData.lastWords;
            this.startDate = new Date();
        },
        storeConfig() {
            this.$activityStore( this ).store( {
                difficulty: this.difficulty,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails,
                lastWords: this.lastWords,
            } );
        },
        getDifficultyByLevel( level ) {
            if ( !level )
                level = 3;
            if ( level < 1 )
                level = 1;
            if ( level > 7 )
                level = 7;
            return this.difficulty_config.find( diff => diff.level == level );
        },
        levelUp() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level + 1);
            this.consecutiveWins = 0;
            this.consecutiveFails = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
        },
        levelDown() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level - 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
        },
    }
})
</script>

<style>
#recognition-image {
    justify-content: center;
    align-items: center;
}
#recognition-image img {
    width: 100%;
    max-height: 40vh;
    user-select: none;
}

#recognition-choices {
    height: 100%;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
}
.choiceButton {
    width:100%;
    font-size: 200%;
    color: white;
}

@media (min-width: 576px) {
    #recognition-image img {
        max-height: 90vh;
    }
    .choiceButton {
        width:100%;
        font-size: 150%;
        color: white;
    }
}

</style>
