<template>
    <div class="piano">
        <div class="piano-container">
            <div id="c" class="piano-key" @click="click('c')">
                c
            </div>
            <div id="cis" class="piano-key-black" @click="click('cis')">
                cis
            </div>
        </div>
        <div class="piano-container">
            <div id="d" class="piano-key" @click="click('d')">
                d
            </div>
            <div id="eb" class="piano-key-black" @click="click('eb')">
                dis
            </div>
        </div>
        <div class="piano-container">
            <div id="e" class="piano-key" @click="click('e')">
                e
            </div>
        </div>
        <div class="piano-container">
            <div id="f" class="piano-key" @click="click('f')">
                f
            </div>
            <div id="fis" class="piano-key-black" @click="click('fis')">
                fis
            </div>
        </div>
        <div class="piano-container">
            <div id="g" class="piano-key" @click="click('g')">
                g
            </div>
            <div id="gis" class="piano-key-black" @click="click('gis')">
                gis
            </div>
        </div>
        <div class="piano-container">
            <div id="a" class="piano-key" @click="click('a')">
                a
            </div>
            <div id="bb" class="piano-key-black" @click="click('bb')">
                b
            </div>
        </div>
        <div class="piano-container">
            <div id="b" class="piano-key" @click="click('b')">
                h
            </div>
        </div>
        <div class="piano-container">
            <div id="C" class="piano-key" @click="click('C')">
                C
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import { getRandomElement, createPromise } from '../utils/utils.js'

export default defineComponent({
    name: 'ca-piano',
    emits: [
        'handleClick'
    ],
    components: {
    },
    data () {
        return {
            my_melody: null,
            notes: ['c', 'cis', 'd', 'eb', 'e', 'f', 'fis', 'g', 'gis', 'a', 'bb', 'b' ],
            audio: new Map(),
        }
    },
    mounted() {
        this.loadAudio();
    },
    methods: {
        async loadAudio() {
            this.audio.set( 'c', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/c4.mp3' ) ) );
            this.audio.set( 'cis', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/c-4.mp3' ) ) );
            this.audio.set( 'd', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/d4.mp3' ) ) );
            this.audio.set( 'eb', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/d-4.mp3' ) ) );
            this.audio.set( 'e', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/e4.mp3' ) ) );
            this.audio.set( 'f', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/f4.mp3' ) ) );
            this.audio.set( 'fis', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/f-4.mp3' ) ) );
            this.audio.set( 'g', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/g4.mp3' ) ) );
            this.audio.set( 'gis', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/g-4.mp3' ) ) );
            this.audio.set( 'a', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/a5.mp3' ) ) );
            this.audio.set( 'bb', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/a-5.mp3' ) ) );
            this.audio.set( 'b', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/b5.mp3' ) ) );
            this.audio.set( 'C', new Audio( await this.$assetDatabase.getSoundUrl( 'assets/piano/c5.mp3' ) ) );
        },
        getChunk( nKeys ) {
            let played = [];
            for (let n = 0; n < nKeys; n++)
                played.push(getRandomElement(this.notes));
            return played;
        },
        click( note ) {
            this.$emit('handleClick', note);
        },
        mark( note ) {
            $('#' + note)[0].classList.add('piano-key-marked');
        },
        playSound( note ) {
            this.audio.forEach( (value, key) => {
                value.pause();
            });
            $('#' + note)[0].classList.add('piano-key-pressed')
            return createPromise( this.audio.get( note ) ).finally(() => {
                $( '#' + note )[0].classList.remove('piano-key-pressed')})
        },
        playMelody( melody ) {
            if ( melody.length == 0 )
                return;
            var note = melody.shift();
            this.playSound( note );

            if ( melody.length > 0 ) {
                setTimeout(() => {
                    this.playMelody( melody );
                }, 1000);
            }
        },
        test() {
            this.playMelody( ['c','d','e','f','g','g','a','a','a','a','g'] );
        }
    }
})
</script>

<style scoped>

.piano {
        display: flex;
        height: 100%;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

.piano-container {
    position: relative;
    height: min(50vw, 50vh);
    width: 12.5%;
}
.piano-key {
    position:relative;
    width:100%;
    height: 100%;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
    background-color: whitesmoke;
    color: black;
    z-index: 3;
    user-select: none;
}
.piano-key-black {
    position: absolute;
    left: 65%;
    top: 0%;
    height: 55%;
    width: 70%;
    align-items: center;
    text-align: center;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    background-color: black;
    color: antiquewhite;
    z-index: 4;
    user-select: none;
}
.piano-key-pressed {
    background-color: var(--bs-gray);
}
.piano-key-marked {
    background-color: var(--bs-yellow);
    color: black;
}
.choiceButton {
    flex-basis: 66%;
    width:100%;
    font-size: 200%;
}

</style>
