<template>
  <div class="bg-b">
    <div class="notification">
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NotificationOverlay',
    props: {
    },
    mounted () {
    },
    methods: {

    },
})
</script>

<style scoped>
    .bg-b {
        background-color: rgba(0, 0, 0, 0.2);
        z-index:1005;
    }

    .notification{
        font-size:30px;
        color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        width:0;
        height:0;
        margin-left:-250px;
        margin-top:-150px;
        background-color: white;
        text-align:center;
        padding-top:0px;
        padding-left:0px;
        padding-right:0px;
    }

</style>


