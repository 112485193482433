
class Timer {
    constructor( time, callback, mode = "timeout" ) {
        this._time = time;
        this._callback = callback;
        this._mode = mode;
        this._res = null;
        this._completed = false;
    }

    start() {
        console.debug(' Starting Timer ');
        if ( this.res() != null )
            this.stop();
        if ( this.isTimeout() )
            this._res = setTimeout( () => { this.callbackHandler( this._callback ) }, this._time );
        else if ( this.isInterval() )
            this._res = setInterval( () => { this.callbackHandler( this._callback ) }, this._time );
        else
            throw "Unsupported Timer mode.";
    }
    isInterval() {
        return this._mode == "interval";
    }
    isTimeout() {
        return this._mode == "timeout";
    }
    stop() {
        console.debug(' Stopping Timer ');
        if ( this.res() == null )
            return;
        else if ( this.isTimeout() )
            clearTimeout( this.res() );
        else if ( this.isInterval() )
            clearInterval( this.res() );
        this._res = null;
    }

    callbackHandler( f ) {
        console.debug(' Timer firing... ');
        this._completed = true;
        f();
    }
    res() {
        return this._res;
    }

}

class cTimerManager {
    constructor() {
        this._timers = new Map();
    }

    getTimer( timerId ) {
        if ( ! this._timers.has( timerId ) )
            return null;
        return this._timers.get( timerId );
    }
    registerTimer( timerId, callback, time, mode, restart ) {
        console.debug(' Register Timer: ' + timerId );
        if ( this.getTimer( timerId ) != null ) {
            if ( !restart )
                return this.getTimer( timerId );
            this.unregisterTimer( timerId );
        }
        let timer = new Timer(time, callback, mode );
        timer.start();
        this._timers.set( timerId, timer );
        return timer;
    }
    unregisterTimer( timerId ) {
        console.debug(' Unregister Timer: ' + timerId );
        let timer = this.getTimer( timerId );
        if ( timer == null )
            return;
        timer.stop();
        this._timers.delete( timerId );
    }
    unregisterTimers( timerIds ) {
        timerIds.forEach( (timerId) => this.unregisterTimer( timerId ) );
    }
    registerTimeout( timeoutId, callback, time, restart = false ) {
        return this.registerTimer( timeoutId, callback, time, 'timeout', restart);
    }
    unregisterTimeout( timeoutId ) {
        return this.unregisterTimer( timeoutId );
    }
    stopTimer( timerId ) {
        let timer = this.getTimer( timerId );
        if ( timer == null )
            return;
        timer.stop();
    }
    restartTimer( timerId ) {
        let timer = this.getTimer( timerId );
        if ( timer == null )
            return;
        timer.stop();
        timer.start();
    }

    registerInterval( intervalId, callback, time ) {
        return this.registerTimer( intervalId, callback, time, 'interval');
    }
    unregisterIntervall( intervalId ) {
        return this.unregisterTimer( intervalId );
    }

    stopAll() {
        this._timers.forEach( ( timer ) =>  {
            timer.stop();
        } );
    }
    startAll() {
        this._timers.forEach( ( timer ) =>  {
            timer.start();
        } );
    }
}
export const TimerManager = new cTimerManager();
