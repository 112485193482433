<template>
  <div class="wordsalat">
    <div class="row justify-content-center">
        <div class="col-8 col-sm-7 col-md-5 col-lg-4">
            <div id="wordsalad-thumbnail" class="d-flex justify-content-center">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div id="wordsalad-boxes" class="row">
            </div>

            <div id="wordsalad-cards" class="row">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { AssetDatabase } from '../utils/AssetDatabase.js';

import { createPromise, getRandomElement, registerIdleTimers, shuffleArray, unregisterIdleTimers } from '../utils/utils.js'
import eventValues from '../values/eventValues.js';

class WordSaladCharacter {

    static idCounter = 0;
    constructor( character ) {
        this._character = character;
        this._id = WordSaladCharacter.idCounter++;
        this._matched = false;
    }

    id() {
        return this._id;
    }

    character() {
        return this._character.toUpperCase();
    }

    cardId() {
        return 'wordsalad-card-' + this.id();
    }
    boxId() {
        return 'wordsalad-box-' + this.id();
    }
    getCardHtml() {
        return  '<div id="' + this.cardId() + '" class="m-1 align-middle d-flex wordsalad-card border draggable">' +
                '<div class="character"> <div> <p> '+ this.character() + ' </p></div></div>' +
                '</div>';
    }

    getBoxHtml() {
        return  '<div id="' + this.boxId() + '" class="wordsalad-box border border-4 text-muted">' +
                '<p> </p>' +
                '</div>';
    }

    lockCardToBox( boxId ) {
        $('#' + this.cardId()).position({
            my: 'center middle',
            at: 'center middle',
            of: $( '#'+boxId ),
        });
    }

    disableDragging() {
        $('#' + this.cardId()).draggable( 'destroy' );
    }
    matched() {
        return this._matched;
    }
    setMatched( matched ) {
        if ( matched ) {
            $('#' + this.cardId()).append(
                '<div class="checkmark">✔️</div>'
            )
        }
        this._matched = matched;
    }

    cardPosition() {
        var position = $( '#' + this.cardId() ).first().position();
        position.top += 50;
        position.left += 50;
        return position;
    }

    boxPosition() {
        var position = $( '#' + this.boxId() ).first().position();
        position.top += 55;
        position.left += 55;
        return position;
    }

    makeDraggable( callback ) {
        $( '#' + this.cardId() ).draggable( {
            stop: function(e, ui) {
                callback();
            }
        } );
    }
    registerClickCallback( callback ) {
        $( '#' + this.cardId() ).click( callback );
    }
}

async function setThumbnail( thumbnailname ) {
    const baseUrl = "assets/wordsalad/";
    const assetExtension = ".png";
    var assetUrl = baseUrl + thumbnailname + assetExtension;
    $( "#wordsalad-thumbnail" ).append( '<img src="' + await AssetDatabase.getPngUrl(assetUrl) +'" />');
}

function distance( position1, position2 ) {
    var dtop = position1.top - position2.top;
    var dleft = position1.left - position2.left;
    return dtop * dtop + dleft * dleft;
}

function inSameOrder(array1, array2) {
    for ( var i = 0; i < array1.length; i++ )
        if ( array1[i].character() != array2[i].character() )
            return false;
    return true;
}

export default defineComponent({
    name: 'ca-wordsalat',
    emits: [
        'end-win',
        // after a successful round, the game is restarted
        // with the same difficulty.

        'end-level-up',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'end-completed',
        // after beating the final difficulty level.

        'end-fail',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'next',
        // start next activity,

        'exclude',
        // if failed consecutively, exclude the activity
        // from further activities

        'long-idle',
        // Emitted when the player was ideling for at least 3 minutes.
    ],
    components: {
    },
    data () {
        return {
            my_word: "haus",
            dictionary: [],
            difficulty: { level: 2, word_length: 4 },
            difficulty_config: [
                { level: 1, word_length: 3 },
                { level: 2, word_length: 4 },
                { level: 3, word_length: 5 },
                { level: 4, word_length: 6 },
                { level: 5, word_length: 7 },
                { level: 6, word_length: 8 },
                { level: 7, word_length: 9 },
                { level: 8, word_length: 10 },
                { level: 9, word_length: 11 },
            ],
            characters: [],

            consecutiveWins: 0,
            consecutiveMissClicks: 0,
            consecutiveFails: 0,
            idleTimer: null,
            longIdleTimer: null,
        }
    },
    props: {
        restart: Boolean
    },
    mounted () {
        this.loadConfig();
        if ( !this.restart )
            this.consecutiveWins = 0;

        this.$axios.get('/assets/wordsalad/words.json').then( ( response ) => {
            this.dictionary = response.data;
            this.setupGame();
        } );
    },
    beforeUnmount() {
        this.stopIdleTimer();
        this.storeConfig();
    },
    methods: {
        getRandomWord() {
            var oldWord = this.my_word; // stores the word of the last round
            let options = this.dictionary.filter( word => word.word.length == this.difficulty.word_length );

            var newWord = "";
            do {
                newWord = getRandomElement( options );
            } while ( newWord.word === oldWord.word )

            return newWord;
        },
        async setupGame() {
            this.my_word = this.getRandomWord();
            this.$event( this ).new( eventValues.ACTIVITY_START, { level: this.difficulty.level, difficulty: this.difficulty, restart: this.restart, word: this.my_word.word } );

            var word = this.my_word.word.split('');
            word.forEach( character => {
                let ws_card = new WordSaladCharacter( character );
                this.characters.push( ws_card );
            });
            setThumbnail( this.my_word.thumbnail );

            // Setup boxes
            this.characters.forEach( ws_box => {
                $( "#wordsalad-boxes" ).append( ws_box.getBoxHtml() );
            });

            // Setup cards
            // Create copy of cards to shuffle
            let cards = shuffleArray( this.characters.slice() );
            while ( inSameOrder( cards, this.characters ) ) {
                cards = shuffleArray( cards );
            }
            cards.forEach( ws_card => {
                $( "#wordsalad-cards" ).append( ws_card.getCardHtml() );
                ws_card.makeDraggable( this.afterDragCallback );
                ws_card.registerClickCallback( this.handleMissclick );
            } );

            // Fix sizing
            var numberOfLetters = this.my_word.word.length;
            var cardsize = (30 / numberOfLetters) + 'vw';
            var boxsize = ((30+numberOfLetters) / numberOfLetters) + 'vw';
            var fontSize = (22 / numberOfLetters) + 'vw';
            $('.wordsalad-card').css( { fontSize: fontSize } );
            $('.wordsalad-card').css( { width: cardsize } );
            $('.wordsalad-box').css( { width: boxsize } );

        },
        afterDragCallback() {
            // loop over all characters
            var newMatch = false;
            this.characters.forEach( ws_card => {
                this.characters.forEach( ws_box => {
                    if ( ws_card.matched() )
                        return;
                    if ( ws_card.character() != ws_box.character() )
                        return;
                    var dis = distance( ws_card.cardPosition(), ws_box.boxPosition() );
                    if ( dis > 2000 ) // magical threshold
                        return;
                    ws_card.setMatched( true );
                    // Make "Click"
                    createPromise( $('#clickAudio')[0] );
                    ws_card.lockCardToBox( ws_box.boxId() );
                    ws_card.disableDragging();
                    newMatch = true;
                    this.$event( this ).new( eventValues.ACTIVITY_INFO, { matched: ws_card.character() } );
                } );
            } );

            if ( ! newMatch ) {
                this.handleMissclick();
            } else {
                this.consecutiveMissClicks = 0;
            }

            // Check if game has finished
            var finished = true;
            this.characters.forEach( ws_card => {
                if ( finished && !ws_card.matched() ) {
                    finished = false;
                }
            } );

            if ( finished ) {
                this.consecutiveWins++;
                this.consecutiveFails = 0;

                if ( this.consecutiveWins >= 5 && this.difficulty.level == 9 ) {
                    this.consecutiveWins = 0;
                    this.$emit('end-completed');
                }
                else if ( this.consecutiveWins >= 5 ) {
                    this.levelUp();
                    this.$emit('end-level-up');
                } else {
                    this.$emit('end-win');
                }
            }
        },
        handleMissclick() {
            this.consecutiveMissClicks++;
            if ( this.consecutiveMissClicks >= 20 ) {
                this.consecutiveFails++;
                if ( this.consecutiveFails == 2 ) {
                    this.levelDown();
                    this.$emit('end-fail');
                } else if ( this.consecutiveFails >= 3 ) {
                    this.$emit('exclude');
                } else {
                    this.$emit('end-fail');
                }
            }
        },
        startIdleTimer() {
            registerIdleTimers( this );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('my_word') )
                this.my_word = storedData.my_word;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
            this.startDate = new Date();
        },
        storeConfig() {
            this.$activityStore( this ).store( {
                difficulty: this.difficulty,
                my_word: this.my_word,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails,
            } );
        },
        getDifficultyByLevel( level ) {
            if ( !level )
                level = 3;
            if ( level < 1 )
                level = 1;
            if ( level > 9 )
                level = 9;
            return this.difficulty_config.find( diff => diff.level == level );
        },
        levelUp() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level + 1);
            this.consecutiveWins = 0;
            this.consecutiveFails = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
        },
        levelDown() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level - 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
        },
    }
})
</script>

<style>
.wordsalat {
    width: 100%;
}
.wordsalad-card {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    user-select: none;
    margin:1vw;
    height: 10vh;
    padding: 0px;
}
.wordsalad-card .character {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.wordsalad-card p {
    margin: 0;
}
.wordsalad-box {
    user-select: none;
    height: 11vh;
    margin:1.5vw;
    padding: 0;
}

#wordsalad-boxes {
    justify-content: center;
}
#wordsalad-cards {
    justify-content: center;
}
.checkmark {
    position: absolute;
    top:6vh;
    left:5vw;
    z-index: 0;
    font-size: x-large;
    user-select: none;
}

#wordsalad-thumbnail img {
    width:80%;
    max-height: 30vh;
    user-select: none;
    object-fit: contain;
}

</style>
