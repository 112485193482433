<template>
  <div class="bg-b">
    <div class="dialogWindow" id="dialog">
      {{ message }}
      <br />
      <button @click="goahead" class="btn btn-primary text-white">Ja</button>
      &nbsp;
      <button @click="cancel" class="btn btn-primary text-white">Nein</button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Dialog',
    emits: [
        'goahead',
        'cancel',
    ],
    props: {
        message: {
            default: 'Wollen Sie weitermachen?',
            type: String
        }
    },
    methods: {
      goahead() {
        this.$emit('goahead')
      },
      cancel() {
        this.$emit('cancel')
      },
      close() {
        this.$emit('close');
      },
    },
})
</script>

<style>
    .bg-b {
        background-color: rgba(0, 0, 0, 0.5);
        z-index:1005;
    }

.dialogWindow {
    font-size:30px;
    color: black;
    width: 70%;
    max-width: 500px;
    /* position: absolute;
    top: 50%;
    left: 50%;
    width:500px;
    height:150px;
    margin-left:-250px;
    margin-top:-150px; */
    background-color: white;
    border: 2px solid lightgray;
    text-align:center;
    padding-top:30px;
    padding-bottom:30px;
    padding-left:10px;
    padding-right:10px;
}
.dialogWindow > button {
    width: 30%;
    min-width: 50px;
}

</style>
