<template>
  <div class="balloon-game">
    <div class="row">
        <div class="col">
            <div id="balloon-area" @click="missClick">
                <img id="sky" type="image/png"/>
                <img id="balloon" type="image/png" @click="onClick" @dragstart="onClick" @dragstart.prevent @dragend.prevent @dragenter.prevent @drag.prevent @dragover.prevent />
                <img id="grass" type="image/png"/>
            </div>
        </div>
        <!--
            grass
            <a href='https://www.freepik.com/vectors/summer'>Summer vector created by macrovector - www.freepik.com</a>
            sky
            <a href='https://www.freepik.com/vectors/summer'>Summer vector created by starline - www.freepik.com</a>
        -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { TimerManager } from '../utils/TimerManager.js';

import { getRandomElement, randomInteger, registerIdleTimers, shuffleArray, unregisterIdleTimers } from '../utils/utils.js'
import eventValues from '../values/eventValues.js';
class Vec2 {
    constructor( x, y ) {
        this.x = x;
        this.y = y;
    }

    add( vec ) {
        this.x += vec.x;
        this.y += vec.y;
    }
    addf( vec, factor ) {
        this.x += factor * vec.x;
        this.y += factor * vec.y;
    }
    multiply( scalar ) {
        this.x *= scalar;
        this.y *= scalar;
    }
    length() {
        return Math.sqrt( ( this.x * this.x ) + ( this.y * this.y ) );
    }
    mix( vec, ratio ) {
        this.x = ( 1 - ratio ) * this.x + ratio * vec.x;
        this.y = ( 1 - ratio ) * this.y + ratio * vec.y;
    }

}
class Balloon {

    constructor( onCeiling ) {

        this._id = '#balloon';
        this._size = {
            width: $( this._id ).outerWidth(),
            height: $( this._id ).outerHeight()
        }
        this._speed = new Vec2( 0, 0 );
        this._acceleration = new Vec2( 0, 1 ); // General uplift
        this._centerOfMass = new Vec2( 30, 30 );
        this._vLimits = { xMin : -20, xMax : 20, yMin: -10, yMax: 1 }
        this._limits = { xMin : 0, xMax : 500, yMin: 0, yMax: 600 }
        this.updateBoundingBox();
        this._position = new Vec2(
            ( this._limits.xMin + this._limits.xMax ) / 2,
            50 );
        this._onCeiling = onCeiling;
    }

    imageAssetUrl() {
        var balloon_id = randomInteger(8);
        return "assets/balloon/balloon_" + balloon_id + ".png";
    }
    bounce( bouncePoint ) {
        // "Push" the balloon downwards
        this._speed.y = this._vLimits.yMin;

        if ( bouncePoint == undefined ) {
            // Alter the horizontal movement randomly
            this._speed.x += ( 0.5 - Math.random() ) * this._vLimits.xMax ;

        } else {
            // Alter x speed according to click position

            var p = $( this._id ).offset();
            var clickPosition = 60 + p.left - bouncePoint[0]; // [-60 ... +60]
            this._speed.x += clickPosition / 60 * Math.random() * this._vLimits.xMax ;
        }
    }
    limit( value, min, max ) {
        if ( value > max )
            return max;
        if ( value < min )
            return min;
        return value;
    }
    updateBoundingBox() {

        let canvasWidth = $('#app').outerWidth();
        let canvasHeight = $('#app').outerHeight();


        this._limits = {
            xMin : 0.02 * canvasWidth,
            xMax : 0.85 * canvasWidth - this._size.width,
            yMin: 0,
            yMax: 0.9 * canvasHeight - (this._size.height / 2)
        }
    }
    simulate() {
        this._position.addf( this._speed, 0.5 );

        this._speed.add( this._acceleration );
        // slowly reduce x speed.
        this._speed.x *= 0.99;

        // Speed limits
        this._speed.x = this.limit( this._speed.x, this._vLimits.xMin, this._vLimits.xMax )
        this._speed.y = this.limit( this._speed.y, this._vLimits.yMin, this._vLimits.yMax )

        this.updateBoundingBox();

        // Do bounding Box
        if ( this._position.x < this._limits.xMin ){
            this._position.x = this._limits.xMin;
            this._speed.x *= -1;
        }
        if ( this._position.x > this._limits.xMax ){
            this._position.x = this._limits.xMax;
            this._speed.x *= -1;
        }
        if ( this._position.y < this._limits.yMin ){
            this._position.y = this._limits.yMin;
            this._speed.y = 0;
        }
        if ( this._position.y >= this._limits.yMax ){
            this._position.y = this._limits.yMax;
            this._onCeiling();
        }
    }

    draw() {
        $( this._id ).css( {
            'left': this._position.x,
            'bottom': this._position.y,
        } )
        // $( this._id ).style.left = this._position.x;
        // $( this._id ).style.top = this._position.y;
        // $( this._id ).position( {
        //     my: 'center+' + this._position.x + ' bottom-' + this._position.y,
        //     at: 'center bottom',
        //     of: '#balloon-area',
        // } );
    }
}

class Girl {
    constructor() {
        this._divId = '#girl';
        $( this._divId ).position( {
                my: 'center bottom',
                at: 'center bottom',
                of: '#balloon-area',
            } );
    }

    startCrying() {
        $( this._divId ).attr( 'src', '/assets/balloon/crying.png' );
    }
    stopCrying() {
        $( this._divId ).attr( 'src', '/assets/balloon/satisfied.png' );
    }
}

export default defineComponent({
    name: 'ca-balloon',
    emits: [
        'end-win'
    ],
    components: {
    },
    data () {
        return {
            my_balloon: null,
            my_girl: null,
            my_track: null,
            my_animationLoop: null,
            difficulty: {
                level:1,
                vLimits: { xMin : -10, xMax : 10, yMin: -10, yMax: 1 },
            },
            difficulty_dict: [
                {
                    level:1,
                    vLimits: { xMin : -1, xMax : 1, yMin: -10, yMax: 1 },
                },
                {
                    level:2,
                    vLimits: { xMin : -2, xMax : 2, yMin: -10, yMax: 2 },
                },
                {
                    level:3,
                    vLimits: { xMin : -3, xMax : 3, yMin: -10, yMax: 3 },
                },
                {
                    level:4,
                    vLimits: { xMin : -3, xMax : 3, yMin: -8, yMax: 4 },
                },
                {
                    level:5,
                    vLimits: { xMin : -4, xMax : 4, yMin: -8, yMax: 5 },
                },
            ],
            my_game_timer: null,
            consecutivseWins: 0,
            consecutiveFails: 0,
            idleTimer: null,
            longIdleTimer: null,
            badClicks: 0,
            goodClicks: 0,
        }
    },
    props: {
        restart: Boolean
    },
    mounted () {
        this.loadConfig();
        this.startIdleTimer();

        if ( !this.restart )
            this.consecutiveWins = 0;

        this.$event( this ).new( eventValues.ACTIVITY_START, {
            level: this.difficulty.level,
            difficulty: this.difficulty,
            restart: this.restarts
            } );

        this.my_balloon = new Balloon( this.onCeiling );
        this.loadImages();
        this.startAnimationLoop();
    },
    beforeUnmount() {
        this.stopAnimationLoop();
        this.stopIdleTimer( this );
        this.storeConfig();
    },
    methods: {
        async loadImages() {
            $('#grass').attr('src', await this.$assetDatabase.getPngUrl( 'assets/balloon/grass.png' ) );
            $('#balloon').attr('src', await this.$assetDatabase.getPngUrl( this.my_balloon.imageAssetUrl() ) );
            $('#sky').attr('src', await this.$assetDatabase.getPngUrl( 'assets/balloon/sky.png' ) );
        },
        startGameTimer() {
            this.my_game_time = 0;
            TimerManager.registerInterval( 'balloon_game_timer', this.onTimer, 1000 );
        },
        stopGameTimer() {
            TimerManager.unregisterTimer( 'balloon_game_timer' );
        },
        onTimer() {
            this.my_game_time++;
        },
        onClick(event) {
            this.goodClicks++;
            this.my_balloon.bounce( [event.clientX, event.clientY ] );
            this.startIdleTimer();
        },
        missClick(event) {
            this.badClicks++;
            this.startIdleTimer();
        },
        onCeiling() {
            this.stopAnimationLoop();

            this.$event( this ).new( eventValues.ACTIVITY_END, {
                correct: this.my_game_time <= 20 ? eventValues.NOT_CORRECT : eventValues.CORRECT,
                reason: this.my_game_time <= 20 ? eventValues.REASON_FAIL : eventValues.REASON_WIN,
                performed: eventValues.PERFORMED,
                goodClicks: this.goodClicks,
                badClicks: this.badClicks,
                } );

            if ( this.my_game_time <= 20 ) {
                this.consecutiveFails++;
                this.consecutivseWins = 0;
                if ( this.consecutiveFails >= 2 )
                    this.$emit('end-fail', { time: this.my_game_time } );
                else
                    this.$emit('end-fail');
            } else if ( this.my_game_time > 20 && this.consecutiveWins >= 10 && this.difficulty.level < 4 ) {
                this.levelUp();
                this.$emit('end-level-up', { time: this.my_game_time });
            } else if ( this.my_game_time > 20 && this.consecutiveWins >= 10 && this.difficulty.level == 4 ) {
                this.consecutiveWins = 0;
                this.$emit('end-complete');
            } else if ( this.my_game_time > 20 && this.my_game_time < 60 ) {
                this.levelDown();
                this.$emit('end-win', { time: this.my_game_time });
            } else if ( this.my_game_time > 300  ) {
                this.levelUp();
                this.$emit('end-win', { time: this.my_game_time });
            } else if ( this.my_game_time > 20  ) {
                this.consecutiveWins++;
                this.$emit('end-win', { time: this.my_game_time });
            }
        },
        startAnimationLoop( ) {
            this.startGameTimer()

            var fps = 25;

            TimerManager.registerInterval( 'animation_loop', () => {
                this.drawFrame();
                this.animationStep();
            }, 1000 / fps );
        },
        drawFrame() {
            this.my_balloon.draw();
        },
        animationStep() {
            this.my_balloon.simulate();
        },
        stopAnimationLoop() {
            this.stopIdleTimer();
            TimerManager.unregisterTimers( [
                'animation_loop',
                'balloon_game_timer',
                 ] );
        },
        startIdleTimer() {
            registerIdleTimers( this );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
            this.$audioSampler.stop();
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
        },
        storeConfig() {
            this.$activityStore( this ).store( {
                difficulty: this.difficulty,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails,
            } );
        },
        getDifficultyByLevel( level ) {
            if ( !level )
                level = 1;
            if ( level < 1 )
                level = 1;
            if ( level > 4 )
                level = 4;
            return this.difficulty_dict.find( diff => diff.level == level );
        },
        levelUp() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level + 1);
            this.consecutiveWins = 0;
            this.consecutiveFails = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
        },
        levelDown() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level - 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
        },
    }
})
</script>

<style>

#girl {
    position: absolute;
    width:100px;
    height:100px;
    user-select: none;
}
#balloon {

    position: fixed;
    width:120px;
    height:120px;
    user-select: none;
    z-index: 1;
}
#balloon-area {
    width:70vw;
    height:70vh;
    user-select: none;
}

#grass, #sky {
    height: 100px;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 0;
}
#grass {
    bottom: 35px;
}

#sky {
    top: 0;
}

</style>
