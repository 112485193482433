<template>
    <div class="bg-w">
        <transition name="bounce">
            <div class="output"> {{ message }} </div>
        </transition>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: "negative-animation",
    data(){
        return {}
    },
    props: {
        message: String
    },
    mounted() {
    },
    beforeUnmount() {
    }

})
</script>


<style scoped>
    .bg-w {
        background-color: rgba(255, 255, 255, 0.5);
    }
    .output{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 70px;
        z-index:1000;
        color: var(--bs-yellow);
        font-size: 50px;
        background-color: rgba(0, 183, 255, 0.9);
        border-radius: 50%;
        position: relative;
    }

    .bounce-enter-active {
            animation: bounce-in 0.3s;
    }
    .bounce-leave-active {
            animation: bounce-in 0.3s reverse;
    }
</style>
