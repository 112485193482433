import { createStore } from 'vuex'
import auth from './auth'
import activities from './activities'
import events from './events'
import install from './install'

import createPersistedState from "vuex-persistedstate";


export default createStore({
  modules: {
    auth,
    activities,
    events,
    install,
  },
  plugins: [createPersistedState( {overwrite:true} )]
})
