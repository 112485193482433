<template>
    <div id="screen">
        <div id="installPrompt" v-if="showInstallDialog">
            <div id="content">
                <span>
                    Wollen Sie eine Verknüpfung zu unserer App zu Ihrem Startbildschirm hinzufügen?
                </span>
                <div id="choices">
                    <button class="btn btn-danger btn-install" @click="a2hs(false)">
                        Nein
                    </button>
                    <button class="btn btn-success btn-install" @click="a2hs(true)">
                        Ja
                    </button>
                </div>
            </div>
        </div>
        <router-view v-if="appActive" id="activity-canvas"/>
        <footer
            class="footer"
            >
            <div class="row">
                <div class="col-1 d-flex align-items-center justify-content-center" id="fullscreen_button">
                    <button class="btn btn-secondary btn-sm" @click=toggleFullscreen>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>
                        </svg>
                    </button>
                    <button class="btn btn-secondary btn-sm add-button">
                        +
                    </button>
                </div>
                <div class="col">
                    <div>Icons made by <a href='https://www.freepik.com' title='Freepik'>Freepik</a> from <a href='https://www.flaticon.com/' title='Flaticon'>www.flaticon.com</a>; </div>
                    <div id="copyright"></div>
                </div>
            </div>
        </footer>
        <audio id="clickAudio" src="/assets/sounds/click.mp3"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeFullscreen, toggleFullscreen } from './utils/utils'
import eventValues from './values/eventValues'

var hidden, visibilityChange;
import { TimerManager } from './utils/TimerManager'
import { markRaw } from '@vue/reactivity';

const NUMBER_OF_DISMISSALS_BEFORE_WE_DO_NOT_ASK_ANYMORE = 2;

export default {
    name: "App",
    data() {
        return {
            fullscreen: false,
            appActive: true,
            showInstallDialog: false,
            installable: false,
            installPrompt: null,
            promptDismissed: false,
            loadingComplete: false,
        }
    },
    computed : {
        ...mapGetters( {
          isLoggedIn: 'auth/authenticated',
          numberOfDismissals: 'install/numberOfDismissals',
        } ),
    },
    methods: {
        ...mapActions( {
            increaseDismissals: 'install/increaseDismissals',
            resetDismissals: 'install/resetDismissals',
            updateUserData: 'auth/updateUser',
            pushState: 'activities/pushState',
            pullState: 'activities/pullState',
            pushEvents: 'events/push',
        } ),
        a2hsPrepare() {
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault();

                console.log( this.numberOfDismissals );

                if ( this.promptDismissed ||
                     this.numberOfDismissals >= NUMBER_OF_DISMISSALS_BEFORE_WE_DO_NOT_ASK_ANYMORE )
                    return;
                // Stash the event so it can be triggered later.
                this.installPrompt = e;
                this.installable = true;
            });
        },
        a2hs( install ) {
            console.log( "a2hs " + install );
            this.showInstallDialog = false;
            // Not installable
            if ( ! install ) {
                this.increaseDismissals();
                return;
            }

            if ( this.installPrompt == null )
                return;

            this.installPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.installPrompt.userChoice.then( (choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                    this.increaseDismissals();
                    this.promptDismissed = true;
                }
                this.installPrompt = null;
            });
        },
        startSession() {
            console.log( "startSession() " + this.installable );
            this.pullState();
            this.$event(this).new( eventValues.SESSION_START );
            this.promptForInstallIfAppropriate();
        },
        endSession() {
            this.$event(this).new( eventValues.SESSION_END );
            this.pushState();
        },
        promptForInstallIfAppropriate() {
            if ( this.installable && this.loadingComplete )
                this.showInstallDialog = true;
        },
        async logout(e) {
            this.$event( this ).new( eventValues.SESSION_END );
            await this.signOut();
            this.$router.replace( { name: 'login' } )
        },
        toggleFullscreen() {
            toggleFullscreen();
        },
        onVisibilityChange() {
            if (document[hidden]) {
                //console.log( "invisible" );
                this.$audioSampler.stop();
                TimerManager.stopAll();
                let videos = $('video');
                for ( let videoIndex = 0; videoIndex < videos.length; videoIndex++ ) {
                    let v = videos[videoIndex];
                    v.pause();
                }
            } else {
                //console.log( "visbile" );
                TimerManager.startAll();
                let videos = $('video');
                for ( let videoIndex = 0; videoIndex < videos.length; videoIndex++ ) {
                    let v = videos[videoIndex];
                    v.play();
                }
            }
        },
        unmount() {

            if ( this.$store.state.auth.authenticated ) {
                this.$event(this).new( eventValues.SESSION_END );
                this.$store.dispatch('activities/pushState');
            }
            this.appActive = false;
        },
        async registerServiceWorker() {
            console.log('Trying to register ServiceWorker.');
            if ( ! ('serviceWorker' in navigator ) ) {
                console.warn('ServiceWorker not supported.');
                return;
            }

            try {
                const registration = await navigator.serviceWorker.register(
                    '/sw.js', {
                        scope: '/',
                    }
                );
                if (registration.installing) {
                    console.log('Service worker installing');
                } else if (registration.waiting) {
                    console.log('Service worker installed');
                } else if (registration.active) {
                    console.log('Service worker active');
                }
            } catch (error) {
                console.error(`Registration failed with ${error}`);
            }
        }
    },
    watch: {
        $route: function (route) {
            this.$audioSampler.stop();
            this.pushState();
            this.pushEvents();
        },
        isLoggedIn : function( loggedIn ){
            if ( loggedIn ) {
                this.startSession();
            } else {
                this.endSession();
                this.resetDismissals();
                this.$router.push('login');
            }
        },
        showInstallDialog : function ( newValue ) {
            if ( newValue ) {
                TimerManager.stopAll();
            } else {
                TimerManager.startAll();
            }
        },
    },
    created() {
        window.onbeforeunload = this.unmount;
    },
    mounted() {
        this.a2hsPrepare();
        this.registerServiceWorker();

        this.updateUserData().then( () => {
            if ( this.isLoggedIn ) {
                this.startSession();
            }
        } ).catch( (error) => {
            this.$router.push('login');
        } );

        // https://developer.mozilla.org/de/docs/Web/API/Page_Visibility_API

        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        document.addEventListener(visibilityChange, this.onVisibilityChange, false);
        this.loadingComplete = true;
    },
    beforeUnmount() {
        if ( this.$store.state.auth.authenticated ) {
            this.$event(this).new( eventValues.SESSION_END );
            this.pushState();
        }
    },
}
</script>

<style>

html, body, #app, #screen {
    height: 100%;
    box-sizing: border-box;
}
nav {
    position: absolute;
    top: 0%;
    left: 5%;
    height: 5%;
}

#activity-canvas {
    padding:1%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.footer {
    position: fixed;
    left: 0%;
    bottom: 0%;
    width: 100%;
    max-height: 50px;
    padding-left:5px;
    padding-right:5px;
    background-color: var(--bs-light);
    overflow: hidden;
}
</style>

<style scoped>
a, #copyright {
    color: var(--bs-dark);
}
#installPrompt {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}
#installPrompt > #content {
    width: 30%;
    min-width: 10em;
    background: white;
    border-radius: 1em;
    padding: 1em;
    box-shadow: 7px 9px 10px #888888;
    text-align: center;
}

#content > #choices {
    display: flex;
    align-content: center;
    justify-content: space-around;
}
#choices > .btn-install {
    width: 30%;
    min-width: 4em;
    margin-top: 0.5em;
}
</style>
