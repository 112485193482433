
import { getRandomElement, createPromise } from '../utils/utils.js'
import { AssetDatabase } from './AssetDatabase.js';



async function addSoundSamples( soundLib, soundId, numberOfSamples ) {
    soundLib.set( soundId, [] );
    let soundStrings = [];
    if ( numberOfSamples == 1 ) {
        soundStrings.push( 'assets/strings/' + soundId + '.mp3' );
    } else {
        for ( var i = 1; i <= numberOfSamples; i++ ) {
            soundStrings.push( 'assets/strings/' + soundId + '_' + i + '.mp3' );
        }
    }
    for ( let i = 0; i < soundStrings.length; i++ ) {
        let item = soundStrings[ i ];
        let audioElement = new Audio( await AssetDatabase.getSoundUrl( item ) );
        soundLib.get( soundId ).push( audioElement );
    }
}

class cAudioSampler {
    constructor() {
        this._audioLib = new Map();
    }
    async setup() {
        this._audioLib = new Map();

        // intros
        addSoundSamples( this._audioLib, 'relaxation_intro', 2 );
        addSoundSamples( this._audioLib, 'movie_intro', 2 );
        addSoundSamples( this._audioLib, 'sing_intro', 2 );
        addSoundSamples( this._audioLib, 'memory_intro', 2 );
        addSoundSamples( this._audioLib, 'balloon_intro', 2 );
        addSoundSamples( this._audioLib, 'puzzle_intro', 2 );
        addSoundSamples( this._audioLib, 'piano_intro', 3 );
        addSoundSamples( this._audioLib, 'cats_intro', 2 );
        addSoundSamples( this._audioLib, 'wordsalad_intro', 2 );
        addSoundSamples( this._audioLib, 'recognition_intro', 2 );


        // instructions
        addSoundSamples( this._audioLib, 'balloon_instructions', 4 );
        addSoundSamples( this._audioLib, 'memory_instructions', 8 );
        addSoundSamples( this._audioLib, 'puzzle_instructions', 6 );
        addSoundSamples( this._audioLib, 'piano_instructions', 6 );
        addSoundSamples( this._audioLib, 'wordsalad_instructions', 2 );
        addSoundSamples( this._audioLib, 'cats_instructions', 4 );
        addSoundSamples( this._audioLib, 'recognition_instructions', 4 );

        //misc
        addSoundSamples( this._audioLib, 'are_you_fine', 2 );
        addSoundSamples( this._audioLib, 'good', 12 );
        addSoundSamples( this._audioLib, 'try_again', 2 );
        addSoundSamples( this._audioLib, 'continue', 4 );
        addSoundSamples( this._audioLib, 'harder', 2 );
        addSoundSamples( this._audioLib, 'relax', 4 );
    }
    playSound( soundId ) {
        let audio = getRandomElement( this._audioLib.get( soundId ) );
        if (audio != null)
            return createPromise(audio);
    }
    stop() {
        this._audioLib.forEach( (audios ) =>  {
            audios.forEach( audio => {
                audio.pause();
                audio.currentTime = 0;
            } );
        } );
    }
};

export const AudioSampler = new cAudioSampler();
