<template>
    <div class="cats-board">
        <img :class="'cat ' + cat.index" v-for="cat in cats" v-bind:key="cat.index" :src="cat.url" @click="click(cat)"/>
    </div>
</template>

<script>
import { getRandomElement, randomInteger, createPromise } from "../utils/utils"
import { defineComponent } from 'vue'
import { AssetDatabase } from '../utils/AssetDatabase.js';

function assetUrl(sound) {
    const baseUrl = "assets/cat/cat";
    const assetExtension = ".png";
    return baseUrl + (sound ? '_sound' : '') + assetExtension;
}
let noSoundUrl = "";
let soundUrl = "";
let meows = [];

class Cat {
    constructor( index ) {
        this.index = index;
        this.url = noSoundUrl;
        this.meow = meows[ index ];
    }

    playSound() {
        this.url = soundUrl;
        return createPromise ( this.meow ).finally( () => {
            this.url = noSoundUrl
        } );
    }

    mark() {
        this.url = soundUrl;
    }

};

export default defineComponent({
    name: 'ca-cats',
    emits: [
        'handleClick'
    ],
    components: {
    },
    data () {
        return {
            cats: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            noSoundUrl = await AssetDatabase.getPngUrl( assetUrl(false) );
            soundUrl = await AssetDatabase.getPngUrl( assetUrl(true) );
            for ( let i = 0; i < 5; i++ )
                meows.push( new Audio( await AssetDatabase.getSoundUrl("assets/cat/cat" + randomInteger(2) + ".mp3") ) );
            this.cats = [ new Cat(0), new Cat(1), new Cat(2), new Cat(3), new Cat(4) ];
        },
        getChunk( nCats ) {
            let played = []
            for (let n = 0; n < nCats; n++)
                played.push(getRandomElement(this.cats).index);
            return played
        },
        playSound(index) {
            return this.cats[index].playSound();
        },
        mark(index) {
            this.cats[index].mark();
        },
        click (cat) {
            this.$emit('handleClick', cat.index);
        }
    }
})
</script>

<style>
    .cats-board {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        height: 100%;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .cat {
        width: 25%;
        margin: 10px;
    }
</style>
