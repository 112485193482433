<template>
    <div class="wrapping_container">
        <component ref="activity" @handleClick="handleClick" v-bind:is="currentActivity"></component>
        <Notification v-if="notify" :message="notificationMessage" @goahead="playMelody"/>
        <NotificationOverlay v-if="waitForMelody"/>
    </div>
</template>

<script>
import Cats from "../cognitive_activities/Cats"
import Piano from "../cognitive_activities/Piano"
import eventValues from '../values/eventValues.js'
import Notification from '../modal/Notification'
import NotificationOverlay from '../modal/NotificationOverlay'

import { registerIdleTimers, secondsSince, unregisterIdleTimers } from '../utils/utils.js'
import { defineComponent } from 'vue'
import { AssetDatabase } from "../utils/AssetDatabase"
export default defineComponent({
    emits: [
        'end-win',
        // after a successful round, the game is restarted
        // with the same difficulty.

        'end-level-up',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'end-completed',
        // after beating the final difficulty level.

        'end-fail',
        // after an unsuccessfull round, the game is restarted
        // with a lower difficulty.

        'next',
        // start next activity,

        'exclude',
        // if failed consecutively, exclude the activity
        // from further activities

        'long-idle',
        // Emitted when the player was ideling for at least 3 minutes.
    ],
    components: {
        Cats,
        Piano,
        Notification,
        NotificationOverlay
    },
    data () {
        return {
            currentActivity: "",
            played: [],
            difficulty: 1,
            consecutiveWins: 0,
            consecutiveMissClicks: 0,
            consecutiveFails: 0,
            blockClicks: false,
            startDate: null,
            notify: false,
            waitForMelody: false,
            notificationMessage: "Achtung, hören Sie gut zu!",
        }
    },
    props: {
        restart: Boolean
    },
    mounted () {
        this.currentActivity = this.$route.params.name;
        this.consecutiveMissClicks = 0;
        this.startDate = new Date();
        this.loadRestartButton();
        console.log( this.currentActivity );
        if ( this.currentActivity == 'cats' ) {
            this.notificationMessage = "Passen Sie auf welche Katzen miauen!"
        } else {
            this.notificationMessage = "Passen Sie auf welche Tasten gespielt werden!"
        }
        this.mountTimer = setTimeout(()=>{this.init()}, 1000);

    },
    beforeUnmount() {
        clearTimeout(this.mountTimer)
        this.stopIdleTimer();
        this.storeConfig();
        $('#restartBtn').detach()
    },
    methods: {
        init() {
            this.loadConfig();
            if ( !this.restart )
                this.consecutiveWins = 0;

            this.restartIdleTimer();
            this.played = this.$refs.activity.getChunk(this.difficulty);
            this.$event(this.$refs.activity).new( eventValues.ACTIVITY_START, { level: this.difficulty, restart: this.restart } );
            this.notify=true
        },
        async loadRestartButton() {
            var restartImageUrl = await AssetDatabase.getPngUrl( 'assets/common/restart.png' );
            $('#sideMenu').append('<img id="restartBtn" class="click" src="' + restartImageUrl + '"/>');
            $('#restartBtn').on('click', ()=>{this.notify=true})
        },
        loadConfig() {
            let storedData = this.$activityStore( this.$refs.activity ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
        },
        storeConfig() {
            this.$activityStore( this.$refs.activity ).store( {
                difficulty: this.difficulty,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails
            } );
        },
        restartIdleTimer() {
            let instructionsAudio = this.currentActivity + '_instructions';
            registerIdleTimers( this, instructionsAudio );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
        },
        async playMelody() {
            this.notify = false
            this.waitForMelody = true;
            setTimeout(()=>{this.playChunk(this.played.slice());}, 1000);
        },
        async playChunk(chunk){
            this.blockClicks = true;
            if ( chunk.length == 0 ) {
                this.blockClicks = false;
                return;
            }
            var idx = chunk.shift();
            await this.$refs.activity.playSound(idx);
            if ( chunk.length > 0 )
                setTimeout(() => {this.playChunk( chunk );}, 1000);
            else {
                setTimeout(() => {
                    this.blockClicks = false;
                    this.waitForMelody = false;
                }, 1000);
            }
        },
        async handleClick ( id ) {
            if (this.blockClicks)
                return;

            this.restartIdleTimer();
            this.blockClicks = true;
            if (this.$route.params.name == 'piano')
                await this.$refs.activity.playSound(id);
            let idx = this.played.indexOf(id);
            // Click was incorrect
            if ( idx == -1 ) {
                this.consecutiveMissClicks++;
                this.checkForFail();
                this.blockClicks = false;
                return;
            }

            this.$event(this.$refs.activity).new( eventValues.ACTIVITY_INFO, {found: id } );
            this.$refs.activity.mark(id);
            setTimeout( () => {
                this.checkForWin(idx);
                this.blockClicks = false;
            }, 500 );
        },
        checkForFail () {
            if (this.consecutiveMissClicks > 4) {
                this.$event( this.$refs.activity ).new( eventValues.ACTIVITY_END, {
                    correct: eventValues.NOT_CORRECT,
                    reason: eventValues.REASON_FAIL,
                    performed: secondsSince( this.startDate ) > 120 ? eventValues.PERFORMED : eventValues.CANCELED
                    } );
                this.levelDown()
                this.consecutiveMissClicks = 0;
                this.consecutiveFails++;
                this.consecutiveWins = 0;

                if (this.consecutiveFails == 2)
                    this.$emit('exclude');
                else
                    this.$emit('end-fail');
            }
        },
        checkForWin (idx) {
            this.consecutiveMissClicks = 0;
            this.consecutiveFails = 0;
            this.played.splice(idx, 1);

            if (this.played.length == 0) {
                this.$event( this.$refs.activity ).new( eventValues.ACTIVITY_END, {
                    correct: eventValues.CORRECT,
                    reason: eventValues.REASON_WIN,
                    performed: eventValues.PERFORMED
                    });

                this.consecutiveWins++;
                this.checkForCompletion();
            }
        },
        checkForCompletion() {
            if (this.consecutiveWins == 5){
                this.consecutiveWins = 0;
                if (this.difficulty == 9)
                    this.$emit('end-completed');
                else
                    this.levelUp();
            }
            else {
                this.$emit('end-win');
            }
        },
        levelUp() {
            this.$event( this.$refs.activity ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
            this.difficulty++
            this.$emit('end-level-up');
        },
        levelDown() {
            this.$event( this.$refs.activity ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
            this.difficulty = Math.max(1, --this.difficulty);
        }

    }
})
</script>

<style scoped>
.wrapping_container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
}
</style>
