import { TimerManager } from "./TimerManager";
import { SECOND, MINUTE } from "../values/times";
import eventValues from "../values/eventValues";

export function shuffleArray( array ) {
    return array.sort( function() {
        return 0.5 - Math.random()
    });
}
export function getRandomElement( array ) {
    if (array && array.length > 0)
        return array[ Math.floor( Math.random() * array.length ) ]
}
export function randomInteger( max ) {
    return Math.floor( Math.random() * (max + 0.99999999) );
}

export function createPromise( media ){
    return new Promise(res => {
        media.play().catch(() => {
            setTimeout(() => {res()}, 1000);
        });
        media.onended = res
    })
}

export async function startVideoOrShowControls() {

    var $video = $('#videoElement');
    if ( $video.length == 0 ) {
        return Promise.resolve();
    }
    return $video[0].play().catch( () => {
        $video.attr('controls', '');
        return Promise.resolve();
    } )
}

export function secondsSince( date ) {
    return (new Date() - date) / 1000;
}

export function distance( x1, y1, x2, y2 ) {
    return Math.sqrt(
        Math.pow( x1 - x2, 2 ) +
        Math.pow( y1 - y2, 2 ) );
}
export function distancePos( pos1, pos2 ) {
    return distance( pos1.x, pos1.y, pos2.x, pos2.y );
}

export function getUniqueRandomIndices( numberOfIndices, maxIndex ) {
    if ( numberOfIndices > maxIndex+1)
        numberOfIndices = maxIndex+1;
    let randomIndices = [];
    for ( var i = 0; i <= maxIndex; i++ ) {
        randomIndices.push( i );
    }

    randomIndices = shuffleArray( randomIndices );
    randomIndices = shuffleArray( randomIndices );
    randomIndices = shuffleArray( randomIndices );
    return randomIndices.slice(0, numberOfIndices );
}
export const delay = ms => new Promise(res => setTimeout(res, ms));

export function toggleFullscreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}
export function makeFullscreen() {
    if (!document.fullscreenElement) {
        try {
            document.documentElement.requestFullscreen();
        } catch ( error ) {
            ; // Nothing do do here. This error is totally expected.
        }
    }
}
export function closeFullscreen() {
    if ( document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
    }
}

export function createAutoplayingVideoElementFromBlob( blob, onended, divId = '#videoScreenDiv' ) {
    const videoElement = document.createElement('video');
    videoElement.controls = false;
    videoElement.autoplay = true;
    videoElement.onended = onended;
    const source = document.createElement('source');
    source.src = URL.createObjectURL( blob );
    source.type = 'video/mp4';
    videoElement.appendChild(source)
    $( divId ).append(videoElement);
}

export function registerIdleTimers( activity, instructionsAudio = null ) {

    let activityName = activity.$options.name ? activity.$options.name : 'app';
    if ( instructionsAudio == null )
        instructionsAudio = activityName.substring(3) + '_instructions';

    TimerManager.registerTimeout( activityName + '_idle_short', () => {
        console.log( activityName + " short idle, play instructions");
        console.log( instructionsAudio );

        activity.$audioSampler.playSound( instructionsAudio );
        activity.$event( activity ).new( eventValues.ACTIVITY_INFO, { idle_timer: 'short' } );
    },  30 * SECOND, true );
    TimerManager.registerInterval( activityName + '_idle_long', () => {
        console.log( activityName + " long idle");
        activity.$event( activity ).new( eventValues.ACTIVITY_INFO, { idle_timer: 'long' } );
        activity.$emit('long-idle');
    },  3 * MINUTE, true );
}

export function unregisterIdleTimers( activity ) {
        let activityName = activity.$options.name ? activity.$options.name : 'app';
    TimerManager.unregisterTimer( activityName + '_idle_short' );
    TimerManager.unregisterTimer( activityName + '_idle_long' );
}
