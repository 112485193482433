<template>
    <div>
        <img id="img-intro" v-if="defaultIntro" class="ratio ratio-16x9 px-3" :src="picSrc" @error="setDefault">
        <VideoScreen v-else :videoSrc="vidSrc" @error="onError" @ended="onEnd"/>
    </div>
</template>

<script>
import { defineComponent} from 'vue'
import VideoScreen from '../components/Video'
import { startVideoOrShowControls } from '../utils/utils';

export default defineComponent({
    name: "Activities",
    components : {
        VideoScreen
    },
    emits: [
        'end-intro'
    ],
    methods : {
        async setDefault() {
            this.picSrc = await this.$assetDatabase.getJpgUrl( this.defaultSrc );
        },
        onError(){
            this.defaultIntro = true;
            setTimeout(() => {this.onEnd()}, 5000)
        },
        onEnd() {
            // must called twice to fire
            if (this.ready)
                this.$emit('end-intro');
            else
                this.ready = true;
        },
        play() {
            let assetId = this.$route.params.name + "_intro";

            setTimeout(async () => {
                await startVideoOrShowControls();
                await this.$audioSampler.playSound(assetId);
                await new Promise(res => setTimeout(res, 2000))
                this.onEnd();
                }, 1000)
        },
        async loadAssetsAndPlay() {
            this.vidSrc = await this.$assetDatabase.getVideoUrl( this.assetsSrc + this.$route.params.name + "_intro.mp4" );
            this.picSrc = await this.$assetDatabase.getJpgUrl( this.assetsSrc + this.$route.params.name + "_intro.jpg" );
            this.play();
        }
    },
    computed: {
    },
    data() { return {
        assetsSrc: "assets/videos/intros/",
        defaultIntro: false,
        defaultSrc: 'assets/videos/intros/default.jpg',
        ready: false,
        vidSrc: null,
        picSrc: null
        };
    },
    created() {
    },
    mounted() {
        this.loadAssetsAndPlay();
    },
    beforeUnmount() {
        clearInterval(this.soundTask)
        this.$audioSampler.stop();
    }
})
</script>
