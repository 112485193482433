import { openDB, deleteDB, wrap, unwrap } from 'idb';

let videosAssets = [
    'assets/videos/breaks/video1.mp4',
    'assets/videos/breaks/video2.mp4',
    'assets/videos/breaks/video3.mp4',
    'assets/videos/breaks/video4.mp4',
    'assets/videos/breaks/video5.mp4',
    'assets/videos/breaks/video6.mp4',
    'assets/videos/intros/balloon_intro.mp4',
    'assets/videos/intros/cats_intro.mp4',
    'assets/videos/intros/memory_intro.mp4',
    'assets/videos/intros/piano_intro.mp4',
    'assets/videos/intros/puzzle_intro.mp4',
    'assets/videos/intros/recognition_intro.mp4',
    'assets/videos/intros/wordsalad_intro.mp4',
    'assets/videos/movie/video1.mp4',
    'assets/videos/movie/video2.mp4',
    'assets/videos/movie/video3.mp4',
    'assets/videos/movie/video4.mp4',
    'assets/videos/movie/video5.mp4',
    'assets/videos/movie/video6.mp4',
    'assets/videos/relaxation/video1.mp4',
    'assets/videos/relaxation/video2.mp4',
    'assets/videos/relaxation/video3.mp4',
    'assets/videos/sing/video1.mp4',
    'assets/videos/sing/video2.mp4',
    'assets/videos/sing/video3.mp4',
    'assets/videos/sing/video4.mp4',
    'assets/videos/sing/video5.mp4',
    'assets/videos/sing/video6.mp4',
    'assets/videos/sing/video7.mp4',
    'assets/videos/sing/video8.mp4',
    'assets/videos/sing/video9.mp4',
    'assets/videos/sing/video10.mp4',
    'assets/videos/sing/video11.mp4',
    'assets/videos/sing/video12.mp4',
    'assets/videos/sing/video13.mp4',
    'assets/videos/sing/video14.mp4',
    'assets/videos/sing/video15.mp4',
    'assets/videos/sing/video16.mp4',
    'assets/videos/sing/video17.mp4',
]

let soundsAssets = [
    'assets/strings/are_you_fine_1.mp3',
    'assets/strings/are_you_fine_1.mp3',
    'assets/strings/are_you_fine_2.mp3',
    'assets/strings/balloon_instructions_1.mp3',
    'assets/strings/balloon_instructions_2.mp3',
    'assets/strings/balloon_instructions_3.mp3',
    'assets/strings/balloon_instructions_4.mp3',
    'assets/strings/balloon_intro_1.mp3',
    'assets/strings/balloon_intro_2.mp3',
    'assets/strings/cats_instructions_1.mp3',
    'assets/strings/cats_instructions_2.mp3',
    'assets/strings/cats_instructions_3.mp3',
    'assets/strings/cats_instructions_4.mp3',
    'assets/strings/cats_intro_1.mp3',
    'assets/strings/cats_intro_2.mp3',
    'assets/strings/continue_1.mp3',
    'assets/strings/continue_2.mp3',
    'assets/strings/continue_3.mp3',
    'assets/strings/continue_4.mp3',
    'assets/strings/good_1.mp3',
    'assets/strings/good_2.mp3',
    'assets/strings/good_3.mp3',
    'assets/strings/good_4.mp3',
    'assets/strings/good_5.mp3',
    'assets/strings/good_6.mp3',
    'assets/strings/good_7.mp3',
    'assets/strings/good_8.mp3',
    'assets/strings/good_9.mp3',
    'assets/strings/good_10.mp3',
    'assets/strings/good_11.mp3',
    'assets/strings/good_12.mp3',
    'assets/strings/harder_1.mp3',
    'assets/strings/harder_2.mp3',
    'assets/strings/memory_instructions_1.mp3',
    'assets/strings/memory_instructions_2.mp3',
    'assets/strings/memory_instructions_3.mp3',
    'assets/strings/memory_instructions_4.mp3',
    'assets/strings/memory_instructions_5.mp3',
    'assets/strings/memory_instructions_6.mp3',
    'assets/strings/memory_instructions_7.mp3',
    'assets/strings/memory_instructions_8.mp3',
    'assets/strings/memory_intro_1.mp3',
    'assets/strings/memory_intro_2.mp3',
    'assets/strings/movie_intro_1.mp3',
    'assets/strings/movie_intro_2.mp3',
    'assets/strings/piano_instructions_1.mp3',
    'assets/strings/piano_instructions_2.mp3',
    'assets/strings/piano_instructions_3.mp3',
    'assets/strings/piano_instructions_4.mp3',
    'assets/strings/piano_instructions_5.mp3',
    'assets/strings/piano_instructions_6.mp3',
    'assets/strings/piano_intro_1.mp3',
    'assets/strings/piano_intro_2.mp3',
    'assets/strings/piano_intro_3.mp3',
    'assets/strings/puzzle_instructions_1.mp3',
    'assets/strings/puzzle_instructions_2.mp3',
    'assets/strings/puzzle_instructions_3.mp3',
    'assets/strings/puzzle_instructions_4.mp3',
    'assets/strings/puzzle_instructions_5.mp3',
    'assets/strings/puzzle_instructions_6.mp3',
    'assets/strings/puzzle_intro_1.mp3',
    'assets/strings/puzzle_intro_2.mp3',
    'assets/strings/realx_movie_1.mp3',
    'assets/strings/realx_movie_2.mp3',
    'assets/strings/recognition_instructions_1.mp3',
    'assets/strings/recognition_instructions_2.mp3',
    'assets/strings/recognition_instructions_3.mp3',
    'assets/strings/recognition_instructions_4.mp3',
    'assets/strings/recognition_intro_1.mp3',
    'assets/strings/recognition_intro_2.mp3',
    'assets/strings/relax_1.mp3',
    'assets/strings/relax_2.mp3',
    'assets/strings/relax_3.mp3',
    'assets/strings/relax_4.mp3',
    'assets/strings/relaxation_intro_1.mp3',
    'assets/strings/relaxation_intro_2.mp3',
    'assets/strings/sing_intro_1.mp3',
    'assets/strings/sing_intro_2.mp3',
    'assets/strings/try_again_1.mp3',
    'assets/strings/try_again_2.mp3',
    'assets/strings/word_salad_instructions_1.mp3',
    'assets/strings/word_salad_instructions_2.mp3',
    'assets/strings/wordsalad_instructions_1.mp3',
    'assets/strings/wordsalad_instructions_2.mp3',
    'assets/strings/wordsalad_intro_1.mp3',
    'assets/strings/wordsalad_intro_2.mp3',
    'assets/piano/a4.mp3',
    'assets/piano/a-4.mp3',
    'assets/piano/a5.mp3',
    'assets/piano/a-5.mp3',
    'assets/piano/b4.mp3',
    'assets/piano/b5.mp3',
    'assets/piano/c4.mp3',
    'assets/piano/c-4.mp3',
    'assets/piano/c5.mp3',
    'assets/piano/d4.mp3',
    'assets/piano/d-4.mp3',
    'assets/piano/e4.mp3',
    'assets/piano/f4.mp3',
    'assets/piano/f-4.mp3',
    'assets/piano/g4.mp3',
    'assets/piano/g-4.mp3',

    'assets/cat/cat0.mp3',
    'assets/cat/cat1.mp3',
    'assets/cat/cat2.mp3',

]

let pngAssets = [
    'assets/win/ellipse.png',
    'assets/common/fast-forward.png',
    'assets/common/pause.png',
    'assets/common/doctor.png',

    'assets/cat/cat.png',
    'assets/cat/cat_sound.png',

    'assets/balloon/balloon_0.png',
    'assets/balloon/balloon_1.png',
    'assets/balloon/balloon_2.png',
    'assets/balloon/balloon_3.png',
    'assets/balloon/balloon_4.png',
    'assets/balloon/balloon_5.png',
    'assets/balloon/balloon_6.png',
    'assets/balloon/balloon_7.png',
    'assets/balloon/balloon_8.png',
    'assets/balloon/grass.png',
    'assets/balloon/sky.png',
    'assets/balloon/satisfied.png',
    'assets/balloon/crying.png',

    'assets/wordsalad/apple.png',
    'assets/wordsalad/apple-tree.png',
    'assets/wordsalad/aquarium.png',
    'assets/wordsalad/axe.png',
    'assets/wordsalad/baby.png',
    'assets/wordsalad/baby-carriage.png',
    'assets/wordsalad/baker.png',
    'assets/wordsalad/balloon.png',
    'assets/wordsalad/bar.png',
    'assets/wordsalad/basin.png',
    'assets/wordsalad/beach-ball.png',
    'assets/wordsalad/bear.png',
    'assets/wordsalad/beetle.png',
    'assets/wordsalad/bench.png',
    'assets/wordsalad/billard.png',
    'assets/wordsalad/boat.png',
    'assets/wordsalad/bonnet.png',
    'assets/wordsalad/book-shelf.png',
    'assets/wordsalad/bowling-equipment.png',
    'assets/wordsalad/boy.png',
    'assets/wordsalad/bracelet.png',
    'assets/wordsalad/branch.png',
    'assets/wordsalad/breakfast.png',
    'assets/wordsalad/bricklayer.png',
    'assets/wordsalad/buildings.png',
    'assets/wordsalad/bus.png',
    'assets/wordsalad/butcher.png',
    'assets/wordsalad/button.png',
    'assets/wordsalad/cake.png',
    'assets/wordsalad/calm.png',
    'assets/wordsalad/car.png',
    'assets/wordsalad/carrot.png',
    'assets/wordsalad/cat.png',
    'assets/wordsalad/chick.png',
    'assets/wordsalad/cinema.png',
    'assets/wordsalad/city-hall.png',
    'assets/wordsalad/clapping.png',
    'assets/wordsalad/coin.png',
    'assets/wordsalad/company.png',
    'assets/wordsalad/cow.png',
    'assets/wordsalad/croissant.png',
    'assets/wordsalad/cruise.png',
    'assets/wordsalad/cupcake.png',
    'assets/wordsalad/damm.png',
    'assets/wordsalad/deer.png',
    'assets/wordsalad/delivery-truck.png',
    'assets/wordsalad/department.png',
    'assets/wordsalad/diary.png',
    'assets/wordsalad/document.png',
    'assets/wordsalad/door.png',
    'assets/wordsalad/drill.png',
    'assets/wordsalad/drilling-rig.png',
    'assets/wordsalad/duck.png',
    'assets/wordsalad/ear.png',
    'assets/wordsalad/easter-bunny.png',
    'assets/wordsalad/eiffel-tower.png',
    'assets/wordsalad/elderly.png',
    'assets/wordsalad/elephant.png',
    'assets/wordsalad/elevator.png',
    'assets/wordsalad/engineer.png',
    'assets/wordsalad/eraser.png',
    'assets/wordsalad/fairground.png',
    'assets/wordsalad/fairy.png',
    'assets/wordsalad/fence.png',
    'assets/wordsalad/file-system.png',
    'assets/wordsalad/fire-station.png',
    'assets/wordsalad/fish.png',
    'assets/wordsalad/flag.png',
    'assets/wordsalad/food.png',
    'assets/wordsalad/fork.png',
    'assets/wordsalad/freezer.png',
    'assets/wordsalad/freight-wagon.png',
    'assets/wordsalad/fridge.png',
    'assets/wordsalad/frog.png',
    'assets/wordsalad/garbage-truck.png',
    'assets/wordsalad/gate.png',
    'assets/wordsalad/giraffe.png',
    'assets/wordsalad/girl.png',
    'assets/wordsalad/glas.png',
    'assets/wordsalad/grandfather.png',
    'assets/wordsalad/grandmother.png',
    'assets/wordsalad/hamburger.png',
    'assets/wordsalad/hands-up.png',
    'assets/wordsalad/hat.png',
    'assets/wordsalad/head.png',
    'assets/wordsalad/heart.png',
    'assets/wordsalad/highway-sign.png',
    'assets/wordsalad/house.png',
    'assets/wordsalad/hut.png',
    'assets/wordsalad/ice-cream-cone.png',
    'assets/wordsalad/jail.png',
    'assets/wordsalad/jellyfish.png',
    'assets/wordsalad/jukebox.png',
    'assets/wordsalad/kayak.png',
    'assets/wordsalad/kite.png',
    'assets/wordsalad/laptop.png',
    'assets/wordsalad/lawn-mower.png',
    'assets/wordsalad/letter.png',
    'assets/wordsalad/limousine.png',
    'assets/wordsalad/magician.png',
    'assets/wordsalad/magic-trick.png',
    'assets/wordsalad/maus.png',
    'assets/wordsalad/medal.png',
    'assets/wordsalad/monkey.png',
    'assets/wordsalad/mushroom.png',
    'assets/wordsalad/musical-note.png',
    'assets/wordsalad/night.png',
    'assets/wordsalad/noteboard.png',
    'assets/wordsalad/olympics.png',
    'assets/wordsalad/pancakes.png',
    'assets/wordsalad/pants.png',
    'assets/wordsalad/park.png',
    'assets/wordsalad/pastry.png',
    'assets/wordsalad/peanut.png',
    'assets/wordsalad/pen.png',
    'assets/wordsalad/pencil-case.png',
    'assets/wordsalad/perucke.png',
    'assets/wordsalad/physician.png',
    'assets/wordsalad/pizza.png',
    'assets/wordsalad/plant.png',
    'assets/wordsalad/playground.png',
    'assets/wordsalad/pointing.png',
    'assets/wordsalad/postman.png',
    'assets/wordsalad/pot.png',
    'assets/wordsalad/potato.png',
    'assets/wordsalad/powerboat.png',
    'assets/wordsalad/private-detective.png',
    'assets/wordsalad/pullover.png',
    'assets/wordsalad/puzzle.png',
    'assets/wordsalad/pyramid.png',
    'assets/wordsalad/rabbit.png',
    'assets/wordsalad/radiator.png',
    'assets/wordsalad/rain.png',
    'assets/wordsalad/reflective-vest.png',
    'assets/wordsalad/relaxation.png',
    'assets/wordsalad/road.png',
    'assets/wordsalad/rum.png',
    'assets/wordsalad/salad.png',
    'assets/wordsalad/sausage.png',
    'assets/wordsalad/school.png',
    'assets/wordsalad/season.png',
    'assets/wordsalad/sewing-machine.png',
    'assets/wordsalad/shirt.png',
    'assets/wordsalad/shoes.png',
    'assets/wordsalad/shower-gel.png',
    'assets/wordsalad/sleeping.png',
    'assets/wordsalad/sloth.png',
    'assets/wordsalad/socks.png',
    'assets/wordsalad/spider-plant.png',
    'assets/wordsalad/spoon.png',
    'assets/wordsalad/stadium.png',
    'assets/wordsalad/steamboat.png',
    'assets/wordsalad/stool.png',
    'assets/wordsalad/sugar.png',
    'assets/wordsalad/suit.png',
    'assets/wordsalad/suitcase.png',
    'assets/wordsalad/sun.png',
    'assets/wordsalad/swimming-pool.png',
    'assets/wordsalad/table-knife.png',
    'assets/wordsalad/table-tennis.png',
    'assets/wordsalad/television.png',
    'assets/wordsalad/ticket.png',
    'assets/wordsalad/toothbrush.png',
    'assets/wordsalad/tower.png',
    'assets/wordsalad/toy-train.png',
    'assets/wordsalad/tractor.png',
    'assets/wordsalad/training.png',
    'assets/wordsalad/trash.png',
    'assets/wordsalad/tree.png',
    'assets/wordsalad/treehouse.png',
    'assets/wordsalad/turkey.png',
    'assets/wordsalad/umbrella.png',
    'assets/wordsalad/vacations.png',
    'assets/wordsalad/walkie-talkie.png',
    'assets/wordsalad/warning-sign.png',
    'assets/wordsalad/washing-powder.png',
    'assets/wordsalad/wheel.png',
    'assets/wordsalad/wires.png',
    'assets/wordsalad/words.json',
    'assets/wordsalad/xmas.png',
    'assets/wordsalad/zoo.png',
    'assets/memory/tiles/0.png',
    'assets/memory/tiles/1.png',
    'assets/memory/tiles/10.png',
    'assets/memory/tiles/100.png',
    'assets/memory/tiles/101.png',
    'assets/memory/tiles/11.png',
    'assets/memory/tiles/12.png',
    'assets/memory/tiles/13.png',
    'assets/memory/tiles/14.png',
    'assets/memory/tiles/15.png',
    'assets/memory/tiles/16.png',
    'assets/memory/tiles/17.png',
    'assets/memory/tiles/18.png',
    'assets/memory/tiles/19.png',
    'assets/memory/tiles/2.png',
    'assets/memory/tiles/20.png',
    'assets/memory/tiles/21.png',
    'assets/memory/tiles/22.png',
    'assets/memory/tiles/23.png',
    'assets/memory/tiles/24.png',
    'assets/memory/tiles/25.png',
    'assets/memory/tiles/26.png',
    'assets/memory/tiles/27.png',
    'assets/memory/tiles/28.png',
    'assets/memory/tiles/29.png',
    'assets/memory/tiles/3.png',
    'assets/memory/tiles/30.png',
    'assets/memory/tiles/31.png',
    'assets/memory/tiles/32.png',
    'assets/memory/tiles/33.png',
    'assets/memory/tiles/34.png',
    'assets/memory/tiles/35.png',
    'assets/memory/tiles/36.png',
    'assets/memory/tiles/37.png',
    'assets/memory/tiles/38.png',
    'assets/memory/tiles/39.png',
    'assets/memory/tiles/4.png',
    'assets/memory/tiles/40.png',
    'assets/memory/tiles/41.png',
    'assets/memory/tiles/42.png',
    'assets/memory/tiles/43.png',
    'assets/memory/tiles/44.png',
    'assets/memory/tiles/45.png',
    'assets/memory/tiles/46.png',
    'assets/memory/tiles/47.png',
    'assets/memory/tiles/48.png',
    'assets/memory/tiles/49.png',
    'assets/memory/tiles/5.png',
    'assets/memory/tiles/50.png',
    'assets/memory/tiles/51.png',
    'assets/memory/tiles/52.png',
    'assets/memory/tiles/53.png',
    'assets/memory/tiles/54.png',
    'assets/memory/tiles/55.png',
    'assets/memory/tiles/56.png',
    'assets/memory/tiles/57.png',
    'assets/memory/tiles/58.png',
    'assets/memory/tiles/59.png',
    'assets/memory/tiles/6.png',
    'assets/memory/tiles/60.png',
    'assets/memory/tiles/61.png',
    'assets/memory/tiles/62.png',
    'assets/memory/tiles/63.png',
    'assets/memory/tiles/64.png',
    'assets/memory/tiles/65.png',
    'assets/memory/tiles/66.png',
    'assets/memory/tiles/67.png',
    'assets/memory/tiles/68.png',
    'assets/memory/tiles/69.png',
    'assets/memory/tiles/7.png',
    'assets/memory/tiles/70.png',
    'assets/memory/tiles/71.png',
    'assets/memory/tiles/72.png',
    'assets/memory/tiles/73.png',
    'assets/memory/tiles/74.png',
    'assets/memory/tiles/75.png',
    'assets/memory/tiles/76.png',
    'assets/memory/tiles/77.png',
    'assets/memory/tiles/78.png',
    'assets/memory/tiles/79.png',
    'assets/memory/tiles/8.png',
    'assets/memory/tiles/80.png',
    'assets/memory/tiles/81.png',
    'assets/memory/tiles/82.png',
    'assets/memory/tiles/83.png',
    'assets/memory/tiles/84.png',
    'assets/memory/tiles/85.png',
    'assets/memory/tiles/86.png',
    'assets/memory/tiles/87.png',
    'assets/memory/tiles/88.png',
    'assets/memory/tiles/89.png',
    'assets/memory/tiles/9.png',
    'assets/memory/tiles/90.png',
    'assets/memory/tiles/91.png',
    'assets/memory/tiles/92.png',
    'assets/memory/tiles/93.png',
    'assets/memory/tiles/94.png',
    'assets/memory/tiles/95.png',
    'assets/memory/tiles/96.png',
    'assets/memory/tiles/97.png',
    'assets/memory/tiles/98.png',
    'assets/memory/tiles/99.png',

    'assets/cat/cat.png',
    'assets/cat/cat_sound.png',
]

let jpegAssets = [
    'assets/puzzle/puzzle000.jpg',
    'assets/puzzle/puzzle001.jpg',
    'assets/puzzle/puzzle002.jpg',
    'assets/puzzle/puzzle003.jpg',
    'assets/puzzle/puzzle004.jpg',
    'assets/puzzle/puzzle005.jpg',
    'assets/puzzle/puzzle006.jpg',
    'assets/puzzle/puzzle007.jpg',
    'assets/puzzle/puzzle008.jpg',
    'assets/puzzle/puzzle009.jpg',
    'assets/puzzle/puzzle010.jpg',
    'assets/puzzle/puzzle011.jpg',
    'assets/puzzle/puzzle012.jpg',
    'assets/puzzle/puzzle013.jpg',
    'assets/puzzle/puzzle014.jpg',
    'assets/puzzle/puzzle015.jpg',
    'assets/puzzle/puzzle016.jpg',
    'assets/puzzle/puzzle017.jpg',
    'assets/puzzle/puzzle018.jpg',
    'assets/puzzle/puzzle019.jpg',
    'assets/puzzle/puzzle020.jpg',
    'assets/puzzle/puzzle021.jpg',
    'assets/puzzle/puzzle022.jpg',
    'assets/puzzle/puzzle023.jpg',
    'assets/puzzle/puzzle024.jpg',
    'assets/puzzle/puzzle025.jpg',
    'assets/puzzle/puzzle026.jpg',
    'assets/puzzle/puzzle027.jpg',
    'assets/puzzle/puzzle028.jpg',
    'assets/puzzle/puzzle029.jpg',
    'assets/puzzle/puzzle030.jpg',
    'assets/puzzle/puzzle031.jpg',
    'assets/puzzle/puzzle032.jpg',
    'assets/puzzle/puzzle033.jpg',
    'assets/puzzle/puzzle034.jpg',
    'assets/puzzle/puzzle035.jpg',
    'assets/puzzle/puzzle036.jpg',
    'assets/puzzle/puzzle037.jpg',
    'assets/puzzle/puzzle038.jpg',
    'assets/puzzle/puzzle039.jpg',
    'assets/puzzle/puzzle040.jpg',
    'assets/puzzle/puzzle041.jpg',
    'assets/puzzle/puzzle042.jpg',
    'assets/puzzle/puzzle043.jpg',
    'assets/puzzle/puzzle044.jpg',
    'assets/puzzle/puzzle045.jpg',
    'assets/puzzle/puzzle046.jpg',
    'assets/puzzle/puzzle047.jpg',
    'assets/puzzle/puzzle048.jpg',
    'assets/puzzle/puzzle049.jpg',
    'assets/puzzle/puzzle050.jpg',
    'assets/puzzle/puzzle051.jpg',
    'assets/puzzle/puzzle052.jpg',
    'assets/puzzle/puzzle053.jpg',
    'assets/puzzle/puzzle054.jpg',
    'assets/puzzle/puzzle055.jpg',
    'assets/puzzle/puzzle056.jpg',
    'assets/puzzle/puzzle057.jpg',
    'assets/puzzle/puzzle058.jpg',
    'assets/puzzle/puzzle059.jpg',
    'assets/puzzle/puzzle060.jpg',
    'assets/puzzle/puzzle061.jpg',
    'assets/puzzle/puzzle062.jpg',
    'assets/puzzle/puzzle063.jpg',
    'assets/puzzle/puzzle064.jpg',
    'assets/puzzle/puzzle065.jpg',
    'assets/puzzle/puzzle066.jpg',
    'assets/puzzle/puzzle067.jpg',
    'assets/puzzle/puzzle068.jpg',
    'assets/puzzle/puzzle069.jpg',
    'assets/puzzle/puzzle070.jpg',
    'assets/puzzle/puzzle071.jpg',
    'assets/puzzle/puzzle072.jpg',
    'assets/puzzle/puzzle073.jpg',
    'assets/puzzle/puzzle074.jpg',
    'assets/puzzle/puzzle075.jpg',
    'assets/puzzle/puzzle076.jpg',
    'assets/puzzle/puzzle077.jpg',
    'assets/puzzle/puzzle078.jpg',
    'assets/puzzle/puzzle079.jpg',
    'assets/puzzle/puzzle080.jpg',
    'assets/puzzle/puzzle081.jpg',
    'assets/puzzle/puzzle082.jpg',
    'assets/puzzle/puzzle083.jpg',
    'assets/puzzle/puzzle084.jpg',
    'assets/puzzle/puzzle085.jpg',
    'assets/puzzle/puzzle086.jpg',
    'assets/puzzle/puzzle087.jpg',
    'assets/puzzle/puzzle088.jpg',
    'assets/puzzle/puzzle089.jpg',
    'assets/puzzle/puzzle090.jpg',
    'assets/puzzle/puzzle091.jpg',
    'assets/puzzle/puzzle092.jpg',
    'assets/puzzle/puzzle093.jpg',
    'assets/puzzle/puzzle094.jpg',
    'assets/puzzle/puzzle095.jpg',
    'assets/puzzle/puzzle096.jpg',
    'assets/puzzle/puzzle097.jpg',
    'assets/puzzle/puzzle098.jpg',
    'assets/puzzle/puzzle099.jpg',
    'assets/puzzle/puzzle100.jpg',

    'assets/recognition/puzzle100.jpg',
    'assets/recognition/angel.jpg',
    'assets/recognition/apple.jpg',
    'assets/recognition/auto.jpg',
    'assets/recognition/baby.jpg',
    'assets/recognition/baum.jpg',
    'assets/recognition/bed.jpg',
    'assets/recognition/biro.jpg',
    'assets/recognition/boat.jpg',
    'assets/recognition/bookshelf.jpg',
    'assets/recognition/butterfly.jpg',
    'assets/recognition/cake.jpg',
    'assets/recognition/candle.jpg',
    'assets/recognition/castle.jpg',
    'assets/recognition/cat.jpg',
    'assets/recognition/coffee.jpg',
    'assets/recognition/cookies.jpg',
    'assets/recognition/fisch.jpg',
    'assets/recognition/flowers.jpg',
    'assets/recognition/glasses.jpg',
    'assets/recognition/haus.jpg',
    'assets/recognition/horse.jpg',
    'assets/recognition/hose.jpg',
    'assets/recognition/hund.jpg',
    'assets/recognition/hut.jpg',
    'assets/recognition/key.jpg',
    'assets/recognition/krug.jpg',
    'assets/recognition/maus.jpg',
    'assets/recognition/medicine.jpg',
    'assets/recognition/motorbike.jpg',
    'assets/recognition/nutcracker.jpg',
    'assets/recognition/oven.jpg',
    'assets/recognition/palmtree.jpg',
    'assets/recognition/pasta.jpg',
    'assets/recognition/phone.jpg',
    'assets/recognition/postbox.jpg',
    'assets/recognition/pot.jpg',
    'assets/recognition/present.jpg',
    'assets/recognition/remote.jpg',
    'assets/recognition/rose.jpg',
    'assets/recognition/shoes.jpg',
    'assets/recognition/slippers.jpg',
    'assets/recognition/snail.jpg',
    'assets/recognition/snowflake.jpg',
    'assets/recognition/stairs.jpg',
    'assets/recognition/sunflower.jpg',
    'assets/recognition/teddy.jpg',
    'assets/recognition/tisch.jpg',
    'assets/recognition/toilet.jpg',
    'assets/recognition/towel.jpg',
    'assets/recognition/uhr.jpg',
    'assets/recognition/wallet.jpg',
    'assets/recognition/words.json',
    'assets/recognition/xmastree.jpg',
    'assets/recognition/zaun.jpg',
    'assets/recognition/zug.jpg',

    'assets/videos/intros/balloon_intro.jpg',
    'assets/videos/intros/cats_intro.jpg',
    'assets/videos/intros/default.jpg',
    'assets/videos/intros/memory_intro.jpg',
    'assets/videos/intros/movie_intro.jpg',
    'assets/videos/intros/piano_intro.jpg',
    'assets/videos/intros/puzzle_intro.jpg',
    'assets/videos/intros/recognition_intro.jpg',
    'assets/videos/intros/relaxation_intro.jpg',
    'assets/videos/intros/sing_intro.jpg',
    'assets/videos/intros/wordsalad_intro.jpg',
]

const donwloadModes = Object.freeze( {
    downloadAfterUrl: 0,
    downloadBeforeUrl: 1,
    dont: 2,
} );

const downloadMode = donwloadModes.downloadBeforeUrl;

var axios = require('axios');
class cAssetDatabase {
    constructor() {

    }
    async downloadAll( onfinish = null, onNext = null, onError = null ) {

        await this.downloadAllAssets( pngAssets, 'pngs_os', onNext, onError );
        await this.downloadAllAssets( jpegAssets, 'jpgs_os', onNext, onError );
        await this.downloadAllAssets( soundsAssets, 'sounds_os', onNext, onError );
        await this.downloadAllAssets( videosAssets, 'videos_os', onNext, onError );

        if ( onfinish != null ) {
            onfinish();
        }
    }
    async downloadAllAssets( assetList, table, onNext, onError ) {
        for ( const asset of assetList ) {
            await this.download( asset, table, onNext, onError );
        }
    }
    async getAllMissingAssets() {
        let missingAssets = [];
        missingAssets.push( await this.getAllMissingAssetsForTable( pngAssets, 'pngs_os' ) );
        missingAssets.push( await this.getAllMissingAssetsForTable( jpegAssets, 'jpgs_os' ) );
        missingAssets.push( await this.getAllMissingAssetsForTable( soundsAssets, 'sounds_os' ) );
        missingAssets.push( await this.getAllMissingAssetsForTable( videosAssets, 'videos_os' ) );
        return missingAssets;
    }
    async getAllMissingAssetsForTable( assetList, table ) {

        let missingAssets = [];
        for ( const asset of assetList ) {
            if ( ! await this.checkIfAssetIsAlreadyDownloaded( asset, table ) )
                missingAssets.push( asset );
        }
        return missingAssets;
    }
    async checkIfAssetIsAlreadyDownloaded( name, table ) {
        let item = await this.get(name, table);
        if ( item != undefined && item != null && item.blob != null )
            return true;
        return false;
    }
    async download( name, table, onDownload = null, onError = null ) {
        const entry = await cAssetDatabase._db.get( table, name );
        if ( entry != undefined )
            return;

        if ( onDownload != null ) {
            onDownload( name );
        }
        console.log('fetching ' + name + ' from network');
        try {
            let response = await axios({
                url: name,
                method: 'GET',
                responseType: 'blob', // important
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                headers: {
                    Accept: 'video/mp4;charset=UTF-8'
                }
              })
              let blob = new Blob([response.data]);
            await cAssetDatabase._db.add( table, { name: name, blob: blob } );
        } catch (e) {
            if ( onError != null ) {
                onError( e );
            }
        }
    }

    async getUrl( name, table ) {
        //console.log( "get " + name );
        if ( downloadMode == donwloadModes.downloadBeforeUrl ) {
            await this.download( name, table );
        }

        let item = await this.get(name, table);
        var url = "";
        if ( item != undefined && item != null && item.blob != null )
            url = URL.createObjectURL( item.blob );
        else {
            // dispatch download
            url = '/' + name;
            if ( downloadMode == donwloadModes.downloadAfterUrl ) {
                this.download( name, table );
            }
        }
        //console.log( url );
        return url;
    }
    get( name, table ) {
        return cAssetDatabase._db.get( table, name );
    }
    getPng( pngName ) {
        return this.get(pngName, 'pngs_os');
    }
    async getPngUrl( pngName ) {
        return this.getUrl(pngName, 'pngs_os');
    }
    getJpg( jpgName ) {
        return this.get(jpgName, 'jpgs_os');
    }
    async getJpgUrl( jpgName ) {
        return this.getUrl(jpgName, 'jpgs_os');
    }
    getVideo( videoName ) {
        return this.get(videoName, 'videos_os');
    }
    async getVideoUrl( videoName ) {
        return this.getUrl(videoName, 'videos_os');
    }
    getSound( soundName ) {
        return this.get(soundName, 'sounds_os');
    }
    async getSoundUrl( soundName ) {
        return this.getUrl(soundName, 'sounds_os');
    }
    async clear( table ) {
        const store = cAssetDatabase._db.transaction( table, 'readwrite' ).objectStore( table );
        await store.clear();
    }
    async clearAll() {
        await this.clear('sounds_os');
        await this.clear('videos_os');
        await this.clear('jpgs_os');
        await this.clear('pngs_os');
    }
}


export async function initDatabase() {
    if ( cAssetDatabase._db && cAssetDatabase._db != undefined && cAssetDatabase._db != null )
        return;

    cAssetDatabase._db = await openDB('dementia_asset_db', 3, {
        upgrade( db, oldVersion, newVersion, transaction) {

            if ( oldVersion < 1) {
                let objectStoreVideo = db.createObjectStore('videos_os', { keyPath: 'name' });
                objectStoreVideo.createIndex('blob', 'blob', { unique: false });
            }
            if ( oldVersion < 2 ) {
                let objectStoreSound = db.createObjectStore('sounds_os', { keyPath: 'name' });
                objectStoreSound.createIndex('blob', 'blob', { unique: false });
            }
            if ( oldVersion < 3 ) {
                let objectStorePng = db.createObjectStore('pngs_os', { keyPath: 'name' });
                objectStorePng.createIndex('blob', 'blob', { unique: false });
                let objectStoreJpg = db.createObjectStore('jpgs_os', { keyPath: 'name' });
                objectStoreJpg.createIndex('blob', 'blob', { unique: false });
            }
        }
    } );
    console.log('Database setup complete');
    console.log( cAssetDatabase._db );
}

export const AssetDatabase = new cAssetDatabase();
