import { filter } from "lodash";


const delimiter = ";";

function getEventsFromStore( store ) {
    return store.getters['events/storage'].slice();
}

function isSameDay( date1, date2 ) {
    return ( date1.getFullYear() == date2.getFullYear() ) &&
        ( date1.getMonth() == date2.getMonth() ) &&
        ( date1.getDate() == date2.getDate() );

    if ( date1.getFullYear() != date2.getFullYear() )
        return false;
    if ( date1.getMonth() != date2.getMonth() )
        return false;
    if ( date1.getDate() != date2.getDate() )
        return false;
    return true;
}
function findMinimumDate( events ) {
    let earliestDate = new Date( Date.now() );
    for ( let i = 0; i < events.length; i++ ) {
        let eventDate = new Date( events[i].timestamp );
        if ( eventDate < earliestDate ) {
            earliestDate = eventDate;
        }
    }
    return earliestDate;
}

function getAllUserIds( events ) {
    let userIds = [];
    for ( let i = 0; i < events.length; i++ ) {
        if ( !userIds.includes( events[i].user_id ) )
            userIds.push( events[i].user_id );
    }
    return userIds;
}

function filterEventyByUserId( events, userId ) {
    let filteredEvents = [];
    for ( let i = 0; i < events.length; i++ ) {
        let event = events[i];
        if ( event.user_id == userId )
            filteredEvents.push( event );
    }
    return filteredEvents;
}

function filterEventsByDay( events, date ) {
    let filteredEvents = [];
    for ( let i = 0; i < events.length; i++ ) {
        let event = events[i];
        let eventDate = new Date( event.timestamp );
        if ( isSameDay( eventDate, date ) )
            filteredEvents.push( event );
    }
    return filteredEvents;
}

function splitEventsToActivities( events ) {
    let splittedEvents = [];
    if ( events.length <= 0 )
        return splittedEvents;

    let currentActivity = "";
    let activityEvents = [];

    for ( let eventIndex = 0 ; eventIndex < events.length; eventIndex++ ) {
        let event = events[ eventIndex ];
        if ( event.activity == currentActivity ) {
            activityEvents.push( event );
        } else {
            if ( activityEvents.length > 0 )
                splittedEvents.push( activityEvents.slice() );
            currentActivity = event.activity;
            activityEvents = [ event ];
        }
    }
    if ( activityEvents.length > 0 )
        splittedEvents.push( activityEvents.slice() );
    return splittedEvents;
}

function eventsToActivityInfo( events ) {
    let activity = {};
    if ( events.length == 0 )
        return activity;

    activity.user_id = events[0].user_id;
    activity.name = events[0].activity;
    activity.type = "KogAk";
    if ( events[0].name == "RelaxActivity" ) {
        if ( events[0].info.hasOwnProperty('type') )
            activity.name = events[0].info.type;
        activity.type = "EnAk";
    }
    activity.start = new Date( events[0].timestamp );
    activity.end = new Date( events[events.length - 1 ].timestamp );
    activity.time = activity.end - activity.start;
    activity.rounds = [];
    let round = null;
    for ( let i = 0; i < events.length; i++ ) {
        let event = events[ i ];
        if ( event.type == "ACTIVITY_START" ) {
            if ( round != null ) {
                activity.rounds.push( round );
                round = null;
            }
            round = {};
            round.start = new Date( event.timestamp );
            round.level = event.info.level;
        }
        if ( round != null ) {

            round.end = new Date( event.timestamp );

            if ( event.type == "ACTIVITY_END" ) {
                round.correct = event.info.correct;
                round.reason = event.info.reason;
                round.performed = event.info.performed;
                round.status = event.info.performed;
            }
        }
    }
    if ( round != null ) {
        activity.rounds.push( round );
        round = null;
    }

    for ( let i = 0; i < activity.rounds.length; i++ ) {
        activity.rounds[ i ].time = activity.rounds[ i ].end - activity.rounds[ i ].start;
        activity.rounds[ i ].time /= 1000;
    }
    if ( activity.rounds.length > 0 )
        activity.levelFinished = activity.rounds[ activity.rounds.length - 1 ].level;
    return activity;
}

function filterActivities( activities ) {
    let filteredActivities = [];
    for ( let i = 0; i < activities.length; i++ ) {
        let dailyActivities = activities[ i ].slice();
        let filteredDailyActivities = [];
        for ( let j = 0; j < dailyActivities.length; j++ ) {
            let activity = dailyActivities[ j ];
            if ( activity.name != "App" &&
                activity.name != "Dashboard"  &&
                activity.name != "side-menu" ) {
                filteredDailyActivities.push( activity );
            }
        }
        if ( filteredDailyActivities.length > 0 )
            filteredActivities.push( filteredDailyActivities );
    }
    return filteredActivities;
}

function getMaxActivitesPerDay( activities ) {
    let maxActivities = 0;
    for ( let i = 0; i < activities.length; i++ ) {
        if ( activities[ i ].length > maxActivities )
            maxActivities = activities[ i ].length;
    }
    return maxActivities;
}
function getMaxRoundsForActivitiy( activities, activityIndex ) {
    let maxRounds = 0;
    for ( let i = 0; i < activities.length; i++ ) {
        if ( activities[ i ].length > activityIndex ) {
            if ( activities[ i ][activityIndex].rounds.length > maxRounds ) {
                maxRounds = activities[ i ][activityIndex].rounds.length;
            }
        }
    }
    return maxRounds;
}

function getMaxRounds( activities ) {
    let maxActivities = getMaxActivitesPerDay( activities );
    let maxRounds = [];
    for ( let activityIndex = 0; activityIndex < maxActivities; activityIndex++ ) {
        maxRounds.push( getMaxRoundsForActivitiy( activities, activityIndex ) );
    }
    return maxRounds;
}

function formatNumber2( number ) {
    if ( number < 10 )
        return '00'+number;
    else if ( number < 100 )
        return '0' + number;
    else
        return '' + number;
}
function formatNumber1( number ) {
    if ( number < 10 )
        return '0'+number;
    else
        return '' + number;
}
function composeTable( activities ) {
    let tableStrings = [];
    tableStrings.push( composeHeader( activities ) );
    for ( let activityIndex = 0; activityIndex < activities.length; activityIndex++ )
        tableStrings.push( composeRow( activities, activityIndex ) );
    return tableStrings;
}
function composeRow( activities, rowIndex ) {
    let maxActivities = getMaxActivitesPerDay( activities );
    let maxRounds = getMaxRounds( activities );

    let rowActivities = activities[ rowIndex ];
    let activityIndex = 0;

    let rowString = rowActivities[0].user_id +  delimiter;
    rowString += rowActivities[0].start.toISOString() + delimiter;
    rowString += rowActivities[0].start.toISOString() + delimiter;
    for ( ; activityIndex < rowActivities.length; activityIndex++ ) {
        rowString += composeActivityString( rowActivities[ activityIndex ], maxRounds[ activityIndex ] );
    }
    while( activityIndex < maxActivities ) {
        rowString += composeActivityString( null, maxRounds[ activityIndex ] );
        activityIndex++;
    }
    return rowString;
}
function composeActivityString( activity, maxrounds ) {
    let tableString = "";
    if ( activity != null ) {
        tableString += activity.name + delimiter;
        tableString += activity.type + delimiter;
        tableString += activity.start.toISOString() + delimiter;
    } else {
        tableString += delimiter + delimiter + delimiter;
    }
    let roundIndex = 0;
    if ( activity != null ) {
        for ( ; roundIndex < activity.rounds.length; roundIndex++ ) {
            let round = activity.rounds[ roundIndex ];
            tableString += round.start.toISOString() + delimiter;
            tableString += round.level + delimiter;
            tableString += round.correct + delimiter;
            tableString += round.status + delimiter;
            tableString += round.reason + delimiter;
            tableString += round.time + delimiter;
        }
    }
    while ( roundIndex < maxrounds ) {
        tableString += delimiter;
        tableString += delimiter;
        tableString += delimiter;
        tableString += delimiter;
        tableString += delimiter;
        tableString += delimiter;
        roundIndex++;
    }
    if ( activity != null ) {
        tableString += activity.levelFinished + delimiter;
        tableString += activity.rounds.length + delimiter;
        tableString += activity.end.toISOString() + delimiter;
    } else {
        tableString += delimiter + delimiter + delimiter;
    }
    return tableString;
}
function composeHeader( activities ) {
    let headerString =
        "ID" + delimiter +
        "login_date" + delimiter +
        "login_time" + delimiter;
    const maxActivities = getMaxActivitesPerDay( activities );
    const maxRounds = getMaxRounds( activities );
    for ( let i = 0; i < maxActivities; i++ ) {
        let activityNumber = formatNumber1( i+1 );
        headerString += 'act_name_' + activityNumber + delimiter +
            'act_type_' + activityNumber + delimiter +
            'act_start_' + activityNumber + delimiter;

        for ( let j = 0; j < maxRounds[i]; j++ ) {
            let roundNumber = formatNumber2( j+1 );
            headerString += 'act_t' + roundNumber + 'start_' + activityNumber + delimiter;
            headerString += 'act_t' + roundNumber + 'lev_' + activityNumber + delimiter;
            headerString += 'act_t' + roundNumber + 'kor_' + activityNumber + delimiter;
            headerString += 'act_t' + roundNumber + 'stat_' + activityNumber + delimiter;
            headerString += 'act_t' + roundNumber + 'abb_' + activityNumber + delimiter;
            headerString += 'act_t' + roundNumber + 'time_' + activityNumber + delimiter;
        }
        headerString += 'act_level_' + activityNumber + delimiter +
            'act_numb_' + activityNumber + delimiter +
            'act_end_' + activityNumber + delimiter;
    }
    return headerString;
}

export class EventExporter {
    constructor( store ) {
        this._store = store;
    }

    /**
     * Queries local store object. Does a quick check if store is
     * initialized and throws exeception if not.
     *
     * @returns local store object.
     */
    store() {
        if ( this._store == null )
            throw "Cannot access events store";
        return this._store;
    }

    exportEvents( userId = null ) {
        if ( userId == null ) {
            let events = getEventsFromStore( this.store() );
            let userIds = getAllUserIds( events );
            let exports = [];
            for ( let userIndex = 0; userIndex < userIds.length; userIndex++ ) {
                let userData = this.exportEvents( userIds[ userIndex ] );
                for ( let j = 0; j < userData.length; j++ ) {
                    exports.push( userData[j] );
                }
            }
            return exports;
        }

        let events = getEventsFromStore( this.store() );
        events = filterEventyByUserId( events, userId );

        let relevantEvents = [];
        let startDate = findMinimumDate( events );
        let date = startDate;
        let endDate = new Date( Date.now() );
        endDate.setDate( endDate.getDate() + 1 );

        let activities = [];
        while( date < endDate ) {
            let dayEvents = filterEventsByDay( events, date );
            let activityEvents = splitEventsToActivities( dayEvents );
            let dailyActivities = []
            for ( let i = 0; i < activityEvents.length; i++ ) {
                dailyActivities.push( eventsToActivityInfo( activityEvents[i] ) );
            }
            activities.push( dailyActivities );
            date.setDate( date.getDate() + 1 );
        }

        let filteredActivities = filterActivities( activities );
        console.log( filteredActivities );
        console.log( getMaxActivitesPerDay( filteredActivities ) );
        console.log( getMaxRounds( filteredActivities ) );

        console.log( composeTable(filteredActivities) );
        return composeTable( filteredActivities );
    }

    exportEventsRaw() {

        let events = getEventsFromStore( this.store() );
        return JSON.stringify( events );
    }
}
