<template>
    <div v-show="ready" class="bg-w">
        <transition name="bounce">
            <div id="outputDiv" class="output">
                {{ message }}
                <img id="star" src="/assets/win/favourite.png" />
            </div>
        </transition>
        <div class="result">
            <img class="bird" :style="animationDuration" src="/assets/win/bird.gif">
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Meanderer from "../utils/Meanderer"

function initPath() {
    const path =
    "M-24.86 143.124c4.903-4.528 9.949-8.9 15.13-13.107 4.89-3.971 9.93-7.815 15.493-10.772 13.66-7.263 29.83-8.764 45.17-6.76 15.34 2.003 30.044 7.31 44.405 13.063 14.362 5.753 28.582 12.01 43.514 16.06 14.931 4.05 30.789 5.83 45.891 2.473 9.095-2.02 17.94-6.006 24.538-12.583 6.06-6.04 9.996-14.117 11.272-22.577 1.977-13.108-2.472-26.791-10.822-37.087-8.35-10.296-20.333-17.322-33.1-20.89-24.71-6.908-52.112-.934-73.348 13.465-8.633 5.854-16.422 13.103-22.131 21.832-5.71 8.73-9.269 18.997-9.29 29.428-.014 7.314 1.753 14.714 5.653 20.902 3.17 5.03 7.67 9.15 12.72 12.29 5.051 3.14 10.647 5.33 16.373 6.936 9.911 2.78 20.34 3.842 30.573 2.716 10.232-1.126 20.259-4.469 28.91-10.048 12.076-7.788 21.17-19.641 33.482-27.05 11.543-6.948 25.39-9.557 38.838-8.74 13.448.816 26.54 4.915 38.765 10.58 13.827 6.407 26.632 14.795 40.032 22.053a272.665 272.665 0 0 0 6.95 3.636";
    const height = 210;
    const width = 297;
    const container = document.querySelector(".result");

    const responsive = new Meanderer({ path, height, width });

    const setPath = () => {
        const scaledPath = responsive.generatePath(
            container.offsetWidth,
            container.offsetHeight
        );
        container.style.setProperty("--path", `"${scaledPath}"`);
    };

    const SizeObserver = new ResizeObserver(setPath);
    SizeObserver.observe(container);
};

function initSize() {
    let ratio = 0.10;
    let size = window.innerWidth * ratio;
    $(".bird").each((i, el) => {
        $(el).css("width", size + "px");
        $(el).css("height" ,size + "px");
    });
}

export default defineComponent({
    name: "positive-animation",
    emits: [
        'restart'
    ],
    data(){
        return {
            backgorundImgUrl:'',
            ready: false,
        }
    },
    computed: {
        animationDuration() {
            return 'animation-duration: ' + this.duration + 's';
        }
    },
    props: {
        message: String,
        duration: Number
    },
    mounted() {
        setTimeout(()=>{$('#bird-sound')[0].play()}, Math.max(0, (this.duration-2)*500));
        this.oldCopyrights = $('#copyright')[0].innerHTML
        $('#copyright')[0].innerHTML +=
        '<a href="https://tenor.com/view/bird-blue-wings-flying-gif-17748964">Bird Blue GIF</a> from <a href="https://tenor.com/search/bird-gifs">Bird GIFs</a>'

        this.loadBackground().then( () => {
            initPath();
            initSize();
            this.ready = true;
        } );

    },
    beforeUnmount() {
        $('#copyright')[0].innerHTML = this.oldCopyrights
    },
    methods: {
        async loadBackground() {
            //this.backgorundImgUrl = await this.$assetDatabase.getPngUrl('assets/win/ellipse.png');
            //await $('.output').css( 'background-image', "url('" + this.backgorundImgUrl + "')" );
        }
    }

})
</script>


<style scoped>
    .bg-w {
        background-color: rgba(255, 255, 255, 0.5);
    }
    .output{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 70px;
        z-index:1000;
        color: var(--bs-yellow);
        font-size: 50px;
        background-color: rgba(0, 183, 255, 0.9);
        border-radius: 50%;
        position: relative;
    }
    #star {
        width: 20%;
        position: absolute;
        top: 67%;
        right: 7%;
    }

    .bounce-enter-active {
            animation: bounce-in 1s;
    }
    .bounce-leave-active {
            animation: bounce-in 1s reverse;
    }

    .result {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .bird{
        position: absolute;
        top: 0%;
        left: 0%;
        z-index: 1001;
        animation-name: show-bird, bird-fly;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        offset-path: path(var(--path));
    }


   @keyframes bounce-in {
     0%  { transform: scale(0.25);}
    25%  { transform: scale(0.5);}
    75%  { transform: scale(0.75);}
    100% { transform: scale(1);}
   }

    @keyframes bird-fly {
        from {offset-distance: 0%;}
        to {offset-distance: 100%;}
    }

</style>
