<template>
    <div style="overflow:scroll;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card-headline text-primary">Home</div>
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="container-fluid p-0">
                                <div class="row">
                                    <div class="col">
                                        <button
                                            class="m-3 btn fs-1 btn-primary text-white btn-lg"
                                            @click.prevent="backToDashboard"
                                            >
                                            Back to the app
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-headline text-primary">Install</div>
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="container-fluid p-0">
                                <div class="row text-muted"
                                    id="installInfo"
                                    v-show="installInfoVisible"
                                    >
                                    <div class="col-1">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <p>
                                            Installing service worker and caching app information...
                                        </p>
                                    </div>
                                </div>
                                <div class="row text-muted"
                                    id="downloadInfo"
                                    v-show="assetDownloading"
                                    >
                                    <div class="col-1">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <p>
                                            Please wait. Downloading Assets might take a while. <br/>
                                            {{ currentAssetDownload }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row"
                                    id="downloadErrors"
                                    v-show="assetDownloading"
                                    >
                                    <div class="col-5">
                                        <p id="errors">
                                            {{ downloadErrors.join("<br>") }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button id="btn-installSW"
                                            @click.prevent="installServiceWorker"
                                            class="m-3 btn fs-1 btn-primary text-white btn-lg"
                                            >
                                            Install / Update Service-Worker
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button id="btn-downloadAssets"
                                            @click.prevent="downloadAssets"
                                            class="m-3 btn fs-1 btn-primary text-white btn-lg"
                                            >
                                            Dowload Assets
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button id="btn-installApp"
                                            class="m-3 btn fs-1 btn-primary text-white btn-lg"
                                            style="display:none;"
                                            >
                                            Install App
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button id="btn-uninstallSW"
                                            @click.prevent="uninstallServiceWorker"
                                            class="m-3 btn fs-1 btn-primary text-white btn-lg"
                                            style="display:none;"
                                            >
                                            Install App
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-headline text-primary">User-Management</div>
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="container-fluid p-0">
                                <div class="row">
                                    <div class="col">
                                        <button
                                            class="m-3 btn fs-1 btn-primary text-white"
                                            @click.prevent="downloadUserData">
                                            Export User activity data
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            class="m-3 btn fs-1 btn-primary text-white"
                                            @click.prevent="downloadUserDataRaw">
                                            Export User activity data (unprocessed)
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button
                                            class="m-3 btn fs-1 btn-primary text-white"
                                            @click.prevent="logoutUser">
                                            Log out current User
                                        </button>
                                    </div>
                                </div>

                                <div class="row"
                                    v-if="uploading">
                                    <div class="col">
                                        <div class="spinner-border text-primary" role="status"></div><br/>
                                        <span> Uploading user information {{ uploadProgess }} of {{ uploadMax }} ... please wait</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-headline text-primary">Reset</div>
                    <div class="card card-default">
                        <div class="card-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col d-flex justify-content-around">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="resetCheckBox" v-model="resetChecked">
                                            <label class="form-check-label" for="resetCheckBox">
                                                I want to reset something
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    v-if="resetChecked"
                                    >
                                    <div class="col">
                                        <button class="m-3 w-75 btn fs-1 btn-primary text-white btn-lg" @click.prevent="clearEvents">
                                            Clear Events
                                        </button>

                                        <button class="m-3 w-75 btn fs-1 btn-primary text-white btn-lg" @click.prevent="clearVuex">
                                            Clear Activities
                                        </button>

                                        <button class="m-3 w-75 btn fs-1 btn-primary text-white btn-lg" @click.prevent="clearEverything">
                                            Reset everything
                                        </button>
                                    </div>
                                    <div class="col">
                                        <button class="m-3 w-75 btn fs-1 btn-primary text-white btn-lg" @click.prevent="clearCache">
                                            Clear Cache
                                        </button>

                                        <button class="m-3 w-75 btn fs-1 btn-primary text-white btn-lg" @click.prevent="clearAssets">
                                            Clear Assets
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createPromise } from '../utils/utils'
import { mapActions } from 'vuex'
import { BProgress } from 'bootstrap-vue'
import { AssetDatabase } from '../utils/AssetDatabase'
import { EventExporter } from '../utils/EventExport'
import { SECOND } from '../values/times'

export default {
    name: "LocalAdmin",
    data() {
        return {
            delays:[],
            uploadProgess: 0,
            uploadMax: 100,
            updateTimer: null,
            uploading: false,
            resetChecked: false,
            assetDownloading: false,
            currentAssetDownload: '',
            downloadErrors: [],
            installInfoVisible: false,
            showInstallModal1: false,
            showInstallModal2: false,
        };
    },
    computed: {
    },
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),
        clearEvents() {
            this.$store.commit( 'events/clear' );
        },
        clearVuex() {
            this.$store.commit( 'activities/clear' );
        },
        clearAssets() {
            this.$assetDatabase.clearAll();
        },
        clearCache( allowAlert = true) {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then( (registrations) => {
                    for(let registration of registrations) {
                        registration.unregister()
                    }
                })
                caches.keys().then(cacheNames => {
                        cacheNames.forEach(cacheName => {
                        caches.delete(cacheName);
                        });
                    })
            }
            if ( allowAlert ) {
                alert( "Cached cleared successfully" );
                this.refreshPage();
            }
        },
        clearEverything() {
            this.clearEvents();
            this.clearVuex();
            this.logoutUser();
            this.clearCache();
        },
        uploadUserData() {
            var adminPass = prompt( "Please Enter the admin password to upload user data." );
            if ( adminPass == null || adminPass != 'a175it') {
                return;
            }

            this.uploading = true;
            this.uploadMax = this.$eventUplaoder.queueSize();
            let self = this;
            this.updateTimer = setInterval( () => {
                self.uploadProgess = self.uploadMax - self.$eventUplaoder.queueSize();
                if ( self.uploadProgess == self.uploadMax ) {
                    this.uploading = false;
                    clearInterval( self.updateTimer );
                }
            }, 1000 );
            this.$eventUplaoder.upload();
        },
        logoutUser() {
            this.signOut()
        },
        backToDashboard() {
            this.$router.push({path: '/dashboard'});
        },
        setCurrentAsset( assetName ) {
            console.log( assetName );
            this.assetDownloading = true;
            this.currentAssetDownload = assetName;
            this.$forceUpdate();
        },
        finishAssetDownload() {
            console.log( "finished" );
            this.assetDownloading = false;
            this.$forceUpdate();
            alert("All assets downloaded.");
            this.refreshPage();
        },
        downloadAssets() {
            let self = this;
            this.assetDownloading = true;
            this.$assetDatabase.downloadAll(
                () => {
                    self.finishAssetDownload();
                },
                ( nextAsset ) => {
                    self.setCurrentAsset( nextAsset );
                }, ( error ) => {
                    console.log( error );
                    this.downloadErrors.push( error );
            }  );
        },
        showInstallInfo( show ) {
          this.installInfoVisible = show;
        },
        async installServiceWorker() {
            let self = this;
            console.log( "Installing SW... ");
            await this.clearCache( false );

            navigator.serviceWorker.register('/sw.js').then( (registration) => {
                registration.onupdatefound = () => {
                    self.showInstallInfo( true );
                    const installingWorker = registration.installing;
                    installingWorker.onstatechange = () => {
                        console.log( "new service worker state: " + installingWorker.state );
                        switch ( installingWorker.state ) {
                            case 'installing':
                                break;
                            case 'installed':
                                break;
                            case 'activating':
                                break;
                            case 'activated':
                                alert("Service Worker installed successfully.");
                                console.log('SW-Installation complete');
                                self.showInstallInfo( false );
                                self.downloadAssets();
                                break;
                            case 'redundant':
                                alert("An error occured during installation of service-worker. Please try it again.");
                                throw Error('The installing service worker became redundant.');
                        }
                    };
                };
            });
        },
        uninstallServiceWorker() {
            this.clearCache();
        },
        downloadUserData() {
            let exporter = new EventExporter( this.$store );
            let exportData = exporter.exportEvents();
            let exportString = exportData.join("\n\r");
            let filename = 'userData.csv';
            let filetype = 'text/csv';
            var blob = new Blob([exportString], { type: filetype });

            var a = document.createElement('a');
            a.download = filename;
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [filetype, a.download, a.href].join(':');
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function() { URL.revokeObjectURL(a.href); }, 5000);
        },
        downloadUserDataRaw() {
            let exporter = new EventExporter( this.$store );
            let exportString = exporter.exportEventsRaw();
            console.log( exportString );
            let filename = 'userData.json';
            let filetype = 'application/json';
            var blob = new Blob([exportString], { type: filetype });

            var a = document.createElement('a');
            a.download = filename;
            a.href = URL.createObjectURL(blob);
            a.dataset.downloadurl = [filetype, a.download, a.href].join(':');
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function() { URL.revokeObjectURL(a.href); }, 5000);
        },
        refreshPage() {
            setTimeout( () => {
                window.location.reload();
            }, 5 * SECOND );
        }
    },
    computed : {
    },
    created() {
    },
    mounted() {
        this.$assetDatabase.getAllMissingAssets().then( (missingAssets) => {
            console.log( missingAssets );
        });
    },
    beforeUnmount() {
    }
}
</script>

<style>
#activity-canvas {
    align-content: unset;
    align-items: unset;
}
</style>
<style scoped>
.dash-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-distance{
  height:35px;
}

.card-headline{
  font-size:20px;
}
</style>
