export default class Cycler {
    _arr
    _placeholder

    constructor( arr, placeholder=null ) {
        this._arr = arr
        this._placeholder = placeholder
    }

    get() {
        return this._arr
    }

    shift() {
        let tmp = this._arr.shift()
        this._arr.push(tmp)
        return tmp
    }

    goTo( el ) {
        if (this._arr.includes(el)){
            if (this._arr.at(-1) != el) {
                this.shift()
                this.goTo(el)
            }
        }
        else
            console.log('no way to ' + el + ' in ' + this._arr)
    }

    empty() {
        return this._arr.every(el => el == this._placeholder)
    }

    next() {
        if ( !this.empty() ) {
            let nextAkt = this._placeholder;
            do {
                nextAkt = this.shift();
                console.log( nextAkt );
            } while( nextAkt == this._placeholder )
            return nextAkt;
        }
    }

    indexOf = ( el ) => {
        return this._arr.indexOf(el)
    }

    replace = (old, niu, offset=0)  => {
        let idx = this._arr.indexOf(old)
        if (idx != -1){
            idx = (idx + offset + this._arr.length) % this._arr.length
            this._arr[idx] = niu
        }
        else
            console.log('no ' + old + ' in ' + this._arr)

    }
}
