<template>
    <div class="d-flex flex-column justify-content-around">
        <img id="nextButton" class="click" @click="next" />
        <img id="pauseButton" class="click" @click="pause" />
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { closeFullscreen, createPromise, makeFullscreen } from '../utils/utils'
import { TimerManager } from "../utils/TimerManager";

export default defineComponent({
    name: "side-menu",
    emits: [
        'next',
        'start'
    ],
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            $('#nextButton').attr('src', await this.$assetDatabase.getPngUrl( 'assets/common/fast-forward.png' ) );
            $('#pauseButton').attr('src', await this.$assetDatabase.getPngUrl( 'assets/common/pause.png' ) );
        },
        async next() {
            makeFullscreen();
            this.$event(this).new( 'CONTROL', {action: 'next' } );
            await createPromise($('#clickAudio')[0]);
            this.$emit('next');
        },
        async start() {
            makeFullscreen();
            this.$event(this).new( 'CONTROL', {action: 'home' } );
            await createPromise($('#clickAudio')[0]);
            this.$emit('start');
        },
        async pause() {
            closeFullscreen();
            TimerManager.stopAll();
            $('#pauseOverlay').css('display','flex');
            $('#pauseOverlay').click( () => {
                $('#pauseOverlay').css('display','none');
                TimerManager.startAll();
                makeFullscreen();
            } );
        }
    }
})
</script>

<style>
  .click{
    cursor: pointer;
  }

</style>
