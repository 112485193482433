<template>
  <div class="puzzle">
    <div class="row" style="width: 100%; height: 100%;">
        <div class="col" style="width: 100%; height: 100%;">
            <div id="puzzle-canvas" style="">
            </div>
        </div>
    </div>
    <!--
    <div class="overlay">
        &nbsp;
    </div>
    -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { randomInteger, getRandomElement, shuffleArray, distancePos, getUniqueRandomIndices, secondsSince, unregisterIdleTimers, registerIdleTimers } from '../utils/utils.js'

import headbreaker from './headbreaker';
import { anchor, Anchor } from './headbreaker/src/anchor.js'
import eventValues from '../values/eventValues.js';


function getRandomEmptySpot2( height, width, pieces, pieceIndex, pieceSize, excludeWidth, excludeHeight ) {
    // Heuristic approach to place puzzle pieces around the canvas
    // Each free piece is moved to a random location, where it does
    // not interfere with other pieces.
    // This approach might break down for large numbers of pieces
    // due to the distance computation.
    var minDistanceToNextPiece = 0;
    var offset = { x: pieceSize, y: pieceSize }
    var step = { x: pieceSize / 5 , y: pieceSize / 5 }

    var newPos = { x: offset.x, y: offset.y };
    newPos.x = offset.x;

    do {
        // Check if we are in the forbbiden area
        if ( newPos.x < excludeWidth && newPos.y < excludeHeight )
            newPos.x = excludeWidth - step.x;

        // Shift the piece to the right
        newPos.x += step.x;

        // if we exceed the border
        if ( newPos.x > ( width - (1.2 * pieceSize) ) ) {
            // Go to the next line
            newPos.y += step.y;
            newPos.x = offset.x;
            minDistanceToNextPiece = 0;
            continue;
        }

        minDistanceToNextPiece = 1000000;
        pieces.forEach( piece => {
            if ( piece.metadata.id == pieceIndex + 1 )
                return;
            var distance = distancePos( newPos, piece.centralAnchor );
            if ( distance < minDistanceToNextPiece )
                minDistanceToNextPiece = distance;
        });
    } while ( minDistanceToNextPiece < 1.4 * pieceSize );

    return newPos;
}

function getRandomEmptySpot( height, width, pieces, pieceIndex, pieceSize ) {
    var newPos = {};
    // Heuristic approach to place puzzle pieces around the canvas
    // Each free piece is moved to a random location, where it does
    // not interfere with other pieces.
    // This approach might break down for large numbers of pieces
    // due to the distance computation.
    var minDistanceToNextPiece = 1000000;
    do {
        newPos.x = pieceSize + Math.random() * (width - 2*pieceSize);
        newPos.y = pieceSize + Math.random() * (height - 2*pieceSize);

        minDistanceToNextPiece = 1000000;
        pieces.forEach( piece => {
            if ( piece.metadata.id == pieceIndex + 1 )
                return;
            var distance = distancePos( newPos, piece.centralAnchor );
            if ( distance < minDistanceToNextPiece )
                minDistanceToNextPiece = distance;
        });
    } while ( minDistanceToNextPiece < pieceSize );

    return newPos;
}

export default defineComponent({
    name: 'ca-puzzle',
    emits: [
        'end-win',
        'end-completed',
        'end-level-up',
        'exclude',
        'end-fail'
    ],
    components: {
    },
    data () {
        return {
            dictionary: [],
            difficulty_config: [
                { level: 1, rows: 2, cols: 2, pieces_to_fit: 1 },
                { level: 2, rows: 3, cols: 3, pieces_to_fit: 3 },
                { level: 3, rows: 4, cols: 4, pieces_to_fit: 3 },
                { level: 4, rows: 4, cols: 5, pieces_to_fit: 4 },
                { level: 5, rows: 5, cols: 5, pieces_to_fit: 8 },
                { level: 6, rows: 5, cols: 6, pieces_to_fit: 10 }
            ],
            difficulty: { level: 2, rows: 3, cols: 3, pieces_to_fit: 3 },
            my_item: null,

            consecutiveWins: 0,
            consecutiveMissClicks: 0,
            consecutiveFails: 0,
            idleTimer: null,
            longIdleTimer: null,
            startDate : null,
            lastPuzzleFile : null,
            clickAudio: null,
            misconnectMode: "snapBack",
            canvas: null,
            canvasInitialWidth: 0,
            //misconnectMode: "color",
        }
    },
    props: {
        restart: Boolean
    },
    mounted () {
        this.loadConfig();
        if ( !this.restart )
            this.consecutiveWins = 0;


        this.$axios.get('/assets/puzzle/puzzles.json').then( ( response ) => {
            this.dictionary = response.data;
            this.my_item = getRandomElement( this.dictionary );
            while( this.my_item.image == this.lastPuzzleFile ) {
                this.my_item = getRandomElement( this.dictionary );
            }
            this.lastPuzzleFile = this.my_item.image;
            this.setupGame();

            this.$event( this ).new( eventValues.ACTIVITY_START, {
                level: this.difficulty.level,
                difficulty: this.difficulty,
                restart: this.restart,
                object:this.my_item.image } );
        } );
        this.startIdleTimer();
        document.addEventListener( 'touchstart', this.clickHandler, false );
        document.addEventListener( 'mousedown', this.clickHandler, false );
    },
    beforeUnmount() {
        document.removeEventListener( 'touchstart', this.clickHandler );
        document.removeEventListener( 'mousedown', this.clickHandler, false );
        this.unregisterReponsiveListeners();
        this.stopIdleTimer();
        this.storeConfig();
    },
    methods: {
        clickHandler( event ) {
            this.consecutiveMissClicks++;
            this.startIdleTimer();
        },
        makeFigureRed( figure ) {
            figure.shape.stroke('red');
            figure.shape.strokeWidth( 10 );
        },
        makeFigureBlack( figure ) {
            figure.shape.stroke('black');
            figure.shape.strokeWidth( 1 );
        },
        checkPiecePosition( piece, figure ) {
            if ( !piece.isAt( piece.originalPosition.x, piece.originalPosition.y ) ) {
                if ( this.misconnectMode == "color" )
                    this.makeFigureRed( figure );
                else {
                    if ( piece.hasOwnProperty( 'startPosition' ) )
                        piece.relocateTo( piece.startPosition.x, piece.startPosition.y );
                }
                this.$event( this ).new( eventValues.ACTIVITY_INFO, {
                    connect: "incorrect",
                 } );
            } else {
                this.makeFigureBlack( figure );
                this.$event( this ).new( eventValues.ACTIVITY_INFO, {
                    connect: "correct",
                } );
            }
        },
        connectCallback( piece, figure, target, targetFigure ) {
            if ( this.clickAudio != null )
                this.clickAudio.play();
            this.checkPiecePosition( piece, figure );
            this.checkPiecePosition( target, targetFigure );

            this.consecutiveMissClicks = 0;
        },
        disconnectCallback( piece, figure, target, targetFigure ) {
            if ( this.clickAudio != null )
                this.clickAudio.play();
            this.makeFigureBlack( figure );
            this.makeFigureBlack( targetFigure );
        },
        async setupGame() {
            let self = this;
            const imageAssetUrl = 'assets/puzzle/' + this.my_item.image;

            this.clickAudio = new Audio( await this.$assetDatabase.getSoundUrl('assets/sounds/click.mp3') )

            let img = new Image();
            img.src = await this.$assetDatabase.getJpgUrl( imageAssetUrl );
            var canvasWidth = $('#puzzle-canvas').outerWidth();
            //var canvasHeight = $('#puzzle-canvas').outerHeight() > canvasWidth ? canvasWidth : $('#puzzle-canvas').outerHeight();
            var canvasHeight = $('#puzzle-canvas').outerHeight();
            let factor = 0.4; // ensure the puzzle takes up at most 0.4 of the screen.
            let pieceSize = 100;
            if ( canvasWidth > canvasHeight ) {
                pieceSize = factor * canvasWidth / this.difficulty.cols;
                if ( pieceSize * this.difficulty.row > 0.75 * canvasHeight )
                    pieceSize = 0.75 * canvasHeight / this.difficulty.rows;
            } else {
                pieceSize = factor * canvasHeight / this.difficulty.rows;
                if ( pieceSize * this.difficulty.cols > 0.75 * canvasWidth )
                    pieceSize = 0.75 * canvasWidth / this.difficulty.cols;
            }
            console.log( [canvasWidth, canvasHeight, pieceSize] );

            img.onload = () => {
                var canvas = new headbreaker.Canvas('puzzle-canvas', {
                    width: canvasWidth,
                    height: canvasHeight,
                    image: img,
                    pieceSize: pieceSize,
                    proximity: 20,
                    borderFill: 0,
                    strokeWidth: 2,
                    lineSoftness: 0,
                    outline: new headbreaker.outline.Rounded(),
                    preventOffstageDrag: true,
                    fixed: true,
                    painter: new headbreaker['painters']['Konva']()
                });
                canvas.adjustImagesToPuzzleHeight();
                canvas.autogenerate( {
                    horizontalPiecesCount: this.difficulty.cols,
                    verticalPiecesCount: this.difficulty.rows
                } );

                var numberOfMissingPieces = this.difficulty.pieces_to_fit;
                var numberOfAvailablePieces = canvas.puzzle.pieces.length;
                let missingPiecesIndices = getUniqueRandomIndices( numberOfMissingPieces, numberOfAvailablePieces - 1);

                for ( let pieceIndex = 0; pieceIndex < numberOfAvailablePieces; pieceIndex++ ) {

                    canvas.puzzle.pieces[pieceIndex].originalPosition = new Anchor(
                        canvas.puzzle.pieces[pieceIndex].centralAnchor.x,
                        canvas.puzzle.pieces[pieceIndex].centralAnchor.y );
                    if ( missingPiecesIndices.includes( pieceIndex ) ) {

                        var newPos = getRandomEmptySpot2( canvasHeight, canvasWidth,
                            canvas.puzzle.pieces, pieceIndex, pieceSize,
                            ( this.difficulty.cols + 1.5 ) * pieceSize,
                            ( this.difficulty.rows + 1.5 ) * pieceSize  );


                        canvas.puzzle.pieces[pieceIndex].relocateTo( newPos.x, newPos.y );
                        canvas.puzzle.pieces[pieceIndex].startPosition = new Anchor( newPos.x, newPos.y );
                        canvas.figures[pieceIndex+1].group.setZIndex(numberOfAvailablePieces - 1);
                    } else {
                        canvas.figures[pieceIndex+1].group.attrs.draggable = false;
                        canvas.figures[pieceIndex+1].group.eventListeners = {};
                        canvas.figures[pieceIndex+1].shape.parent.eventListeners.dragmove = ( dx, dy ) => {};
                        canvas.puzzle.pieces[pieceIndex].locked = true;
                    }
                }
                canvas.draw();
                canvas.onConnect( this.connectCallback );
                canvas.onDisconnect( this.disconnectCallback );
                canvas.attachSolvedValidator();

                canvas.onValid( this.puzzleFinished );
                this.canvas = canvas;
                this.canvasInitialWidth = canvasWidth;
                this.registerReponsiveListeners();
            }
        },
        registerReponsiveListeners() {
            ['resize', 'DOMContentLoaded'].forEach((event) => {
                window.addEventListener( event, this.puzzleResize);
            } );
        },
        unregisterReponsiveListeners() {
            ['resize', 'DOMContentLoaded'].forEach((event) => {
                window.removeEventListener( event, this.puzzleResize);
            } );
        },
        puzzleResize( event ) {
            console.log( "resize event");
            if ( this.canvas == null )
                return;

            console.dir( this.canvas );
            var container = document.getElementById('puzzle-canvas');
            let canvasWidth = this.canvasInitialWidth;
            this.canvas.resize(container.offsetWidth, container.offsetHeight);
            this.canvas.scale(container.offsetWidth / canvasWidth);
            this.canvas.redraw();
        },
        puzzleFinished() {
            this.$event( this ).new( eventValues.ACTIVITY_END, {
                correct: eventValues.CORRECT,
                reason: eventValues.REASON_WIN,
                performed: eventValues.PERFORMED
                } );

            this.consecutiveMissClicks = 0;
            this.consecutiveFails = 0;
            this.consecutiveWins++;
            if ( this.consecutiveWins >= 5 && this.difficulty.level == 6 ) {
                this.consecutiveWins = 0;
                this.$emit('end-completed');
            }
            else if ( this.consecutiveWins >= 5 ) {
                this.levelUp();
                this.$emit('end-level-up');
            } else {
                this.$emit('end-win');
            }
        },
        startIdleTimer() {
            registerIdleTimers( this );
        },
        stopIdleTimer() {
            unregisterIdleTimers( this );
            this.$audioSampler.stop();
        },
        loadConfig() {
            let storedData = this.$activityStore( this ).data;
            if ( storedData.hasOwnProperty('difficulty') )
                this.difficulty = storedData.difficulty;
            if ( storedData.hasOwnProperty('consecutiveWins') )
                this.consecutiveWins = storedData.consecutiveWins;
            if ( storedData.hasOwnProperty('consecutiveFails') )
                this.consecutiveFails = storedData.consecutiveFails;
            if ( storedData.hasOwnProperty('lastPuzzleFile') )
                this.lastPuzzleFile = storedData.lastPuzzleFile;
            this.startDate = new Date();

            console.log( "loading config ");
            console.log( this.difficulty.level );
        },
        storeConfig() {
            console.log( "storing config ");
            console.log( this.difficulty.level );
            this.$activityStore( this ).store( {
                difficulty: this.difficulty,
                consecutiveWins: this.consecutiveWins,
                consecutiveFails: this.consecutiveFails,
                lastPuzzleFile: this.lastPuzzleFile,
            } );
        },
        getDifficultyByLevel( level ) {
            if ( !level )
                level = 2;
            if ( level < 1 )
                level = 1;
            if ( level > 6 )
                level = 6;
            return this.difficulty_config.find( diff => diff.level == level );
        },
        levelUp() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level + 1);
            this.consecutiveWins = 0;
            this.consecutiveFails = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelUp: true } );
        },
        levelDown() {
            this.difficulty = this.getDifficultyByLevel( this.difficulty.level - 1);
            this.consecutiveWins = 0;
            this.$event( this ).new( eventValues.ACTIVITY_INFO, { levelDown: true } );
        },
        handleMissclick() {
            this.consecutiveMissClicks++;
            if ( this.consecutiveMissClicks >= 20 ) {
                this.consecutiveFails++;
                this.$event( this ).new( eventValues.ACTIVITY_END, {
                    correct: eventValues.NOT_CORRECT,
                    reason: eventValues.REASON_FAIL,
                    performed: secondsSince( this.startDate ) > 120 ? eventValues.PERFORMED : eventValues.CANCELED
                    } );
                if ( this.consecutiveFails == 2 ) {
                    this.levelDown();
                    this.$emit('end-fail');
                } else if ( this.consecutiveFails >= 3 ) {
                    this.$emit('exclude');
                } else {
                    this.$emit('end-fail');
                }
            }
        },
    }
})
</script>

<style>
.puzzle {
    width: 100%;
    height: 100%;
}
#puzzle-canvas {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.puzzle .overlay {
    background: rgba(255,255,255,.5);
    z-index : 10;
    position : absolute;
    top : 0;
    left : 0;
    width: 100%;
    height: 100%;
}
</style>
