<template>
    <div>
        <div>
            <h2>Cognitive Activities</h2>
            <ul>
                <li>
                    <router-link to="/activity/memory" class="nav-item nav-link">Memory</router-link>
                </li>
                <li>
                    <router-link to="/activity/wordsalad" class="nav-item nav-link">WordSalad</router-link>
                </li>
                <li>
                    <router-link to="/activity/cats" class="nav-item nav-link">Cats</router-link>
                </li>
                <li>
                    <router-link to="/activity/recognition" class="nav-item nav-link">Recognition</router-link>
                </li>
                <li>
                    <router-link to="/activity/piano" class="nav-item nav-link">Piano</router-link>
                </li>
                <li>
                    <router-link to="/activity/puzzle" class="nav-item nav-link">Puzzle</router-link>
                </li>
                <li>
                    <router-link to="/activity/balloon" class="nav-item nav-link">Balloon</router-link>
                </li>
            </ul>
        </div>

        <div>
            <h2>Relaxation Activities</h2>
            <ul>
                <li>
                    <router-link to='/activity/relaxation' class="nav-item nav-link">Relaxation</router-link>
                </li>
                <li>
                    <router-link to='/activity/movie' class="nav-item nav-link">Movie</router-link>
                </li>                
                <li>
                    <router-link to='/activity/sing' class="nav-item nav-link">Sing</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Activities",
    components : {
    },
    methods : {
    },
    data() { return {
        };
    },
    computed : {
    },
    created() {
    },
    mounted() {
    }
}
</script>
