<template>
    <div>
        <Dialog class="overlay centered" v-show="showDialog" @goahead="goahead" @cancel="cancel"/>
        <Dialog class="overlay centered" message="Wollen Sie noch einmal spielen?" v-show="showDialog2" @goahead="restart" @cancel="activityNextWithoutAnimation"/>
        <component :is="currentGame" :restart="nRefreshs>0"
            @end-win="activityWin"
            @end-level-up="activityLevelUp"
            @end-completed="activityCompleted"
            @end-fail="activityFail"
            @next="activityNext"
            @exclude="activityExclude"
            @long-idle="activityLongIdle"
            ref="currentGame"/>
        <component class="overlay centered" v-if="showAnimation" :is="victoryAnimation" :message="winMessage" :duration="winDuration"/>
        <audio id="bird-sound"/>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import Dialog from "../modal/Dialog"
import CompleteAnimation from "../animations/CompleteAnimation"
import Memory from "../cognitive_activities/Memory"
import WordSalad from "../cognitive_activities/WordSalad"
import Recognition from "../cognitive_activities/Recognition"
import Puzzle from "../cognitive_activities/Puzzle"
import CatPiano from "../cognitive_activities/CatPiano"
import Ballon from "../cognitive_activities/Balloon"
import eventValues from '../values/eventValues'
import PositiveAnimation from '../animations/PositiveAnimation.vue'
import NegativeAnimation from '../animations/NegativeAnimation.vue'

import { MINUTE } from '../values/times'
export default defineComponent({
    name: "Cognitive Activity",
    components : {
        Dialog,
        'completed': CompleteAnimation,
        'positive': PositiveAnimation,
        'negative': NegativeAnimation,
        'ca-memory': Memory,
        'ca-wordsalad': WordSalad,
        'ca-cats': CatPiano,
        'ca-recognition': Recognition,
        'ca-piano': CatPiano,
        'ca-puzzle': Puzzle,
        'ca-balloon': Ballon,
    },
    emits: [
        'completed',
        'canceled',
        'restart'
    ],
    data() { return {
        showAnimation: false,
        showDialog: false,
        showDialog2: false,
        victoryAnimation: "none",
        winMessage: "",
        timerDialog: null,
        };
    },
    computed: {
        currentGame() {
            return "ca-" + this.$route.params.name;
        }
    },
    props: {
        nRefreshs: Number
    },
    methods : {
        async activityWin( context = null ) {
            // win round
            var message = "Super!"
            if ( context != null ) {
                if ( context.hasOwnProperty( 'time' ) ) {
                    // Handles very special case for balloon activity
                    message += " " + context.time + " Sekunden";
                }
            }
            await this.playAnimation( "positive", message);
            if ( this.currentGame == "ca-balloon" ) {
                // show Retry dialog
                setTimeout( () => {
                    this.showAnimation = false;
                    this.showDialog2 = true;
                }, 15 * 1000 );
            } else
                this.restart();
        },
        async activityLevelUp() {
            // win multiple rounds and level up
            await this.playAnimation( "positive", "Perfekt, nächstes Level!" );
            this.restart();
        },
        async activityCompleted(){
            // Play animation
            await this.playAnimation( 'completed' );

            this.showAnimation = false

            // New activity
            this.$emit('completed')
        },
        async activityNextWithoutAnimation() {
            this.$emit('completed');
        },
        async activityFail() {
            await this.playAnimation( 'negative', "Versuchen Sie es noch einmal" );
            this.restart();
        },
        async activityNext() {
            await this.playAnimation( 'negative', "Probieren wir ein anderes Spiel" );
            this.$emit('completed');
        },
        async activityExclude() {
            await this.playAnimation( 'negative', "Lassen Sie uns etwas anderes probieren." );
            this.cancel();
        },
        async activityLongIdle() {
            if ( this.timerDialog == null ) {
                this.timerDialog = setTimeout(() => {
                    this.showDialog = false;
                    let reference = this;
                    if ( this.$refs.currentGame != null )
                        reference = this.$refs.currentGame;

                    this.$event( reference ).new( eventValues.ACTIVITY_END, {
                        correct: eventValues.NOT_CORRECT,
                        reason: eventValues.REASON_INACTIVE,
                        performed: eventValues.CANCELED
                    } );
                    this.timerDialog = null;
                    this.$router.push({path: '/dashboard'});
                }, 15 * MINUTE );
            }
            this.showDialog = true;
            this.$audioSampler.playSound('continue')
        },
        backToSelection(){
          this.$router.push({path: '/activities'})
        },
        restart(){
          this.$emit('restart')
        },
        goahead(){
          this.showDialog = false;
          this.showDialog2 = false;
          clearTimeout(this.timerDialog);
        },
        cancel() {
            this.showDialog = false;
            this.showDialog2 = false;
            clearTimeout(this.timerDialog);
            this.$event( this.$refs.currentGame ).new( eventValues.ACTIVITY_END, {
                correct: eventValues.NOT_CORRECT,
                reason: eventValues.REASON_INACTIVE,
                performed: eventValues.CANCELED
            } );
            this.$emit('canceled')
        },
        playAnimation( animationName, message = "", duration=6 ) {

            // Play animation
            this.victoryAnimation = animationName
            this.winMessage = message;
            this.showAnimation = true;
            this.winDuration = duration;
            return new Promise(res => setTimeout(res, duration * 1000));
        },
        async loadBirdSound() {
            $('#bird-sound').attr('src', await this.$assetDatabase.getSoundUrl('assets/sounds/bird_sound.mp3') );
        }
    },
    mounted() {
        this.loadBirdSound();
    }
})
</script>

<style scoped>
    .overlay {
        left: 0;
        top: 0;
        position: fixed;
        cursor: default;
        z-index: 1000;
    }
</style>
