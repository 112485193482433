<template>

    <div class="container">
        <div class="row">
            <div class="d-flex col col-md-6 col-12 align-items-center justify-content-center mb-3">
                <img id="docImg" type="image/png" src="/assets/common/doctor.png"/>
            </div>
            <div class="col col-md-6 col-12 d-flex flex-column justify-content-center ">
                <div class="row">
                    <div class="d-flex col col-12 align-items-center">
                        <h4 class="display-4">Hallo.</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="d-flex col col-12 align-items-center">
                        <p class="d-flex display-5 justify-content-center">
                            <i>
                                Geht es Ihnen gut?
                            </i>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="d-flex col col-12 align-items-center justify-content-around">
                        <button class="btn fs-1 btn-primary text-white btn-lg choiceButton" @click="userIsFine( true)">Ja</button>
                        <button class="btn fs-1 btn-primary text-white btn-lg choiceButton" @click="userIsFine( false )">Nein</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createPromise, makeFullscreen } from '../utils/utils'
import { TimerManager } from '../utils/TimerManager'

export default {
    name: "Dashboard",
    data() {
        return {
        };
    },
    computed: {
        activityData() {
            let data = this.$store.getters['activities/details']('Activity');
            if ( !data )
                return {};
            return data;
        }
    },
    methods: {
        async userIsFine( fine = false ) {
            makeFullscreen();
            this.$event(this).new( 'CONTROL', { fine: fine } );
            await createPromise( $('#clickAudio')[0] );

            this.$router.push( {
                path: this.getNextActivityPath( fine )
            } );
        },
        getNextActivityPath( cognitiveActivitiyDesired ) {
            let nextActivity = '/activity/memory'; // Default cognitive activity
            if ( !cognitiveActivitiyDesired )
                nextActivity = '/activity/relaxation'; // Default relaxation activity

            if ( cognitiveActivitiyDesired && this.activityData.hasOwnProperty('kogAks') )
                nextActivity = '/activity/' + this.activityData.kogAks.find( el => el != null );
            else if ( !cognitiveActivitiyDesired && this.activityData.hasOwnProperty('enAks') )
                nextActivity = '/activity/' + this.activityData.enAks.find( el => el != null );
            return nextActivity;
        }
    },
    created() {
    },
    mounted() {
        TimerManager.registerTimeout( 'dashboard_idle1', () => { this.$audioSampler.playSound('are_you_fine') },  5 * 1000 );
        TimerManager.registerTimeout( 'dashboard_idle2', () => { this.$audioSampler.playSound('are_you_fine') }, 30 * 1000 );
        TimerManager.registerTimeout( 'dashboard_idle3', this.userIsFine, 60 * 1000 );
    },
    beforeUnmount() {
        TimerManager.unregisterTimers( [
            'dashboard_idle1',
            'dashboard_idle2',
            'dashboard_idle3' ] );
    }
}
</script>

<style scoped>
#docImg {
    width: 80%;
    min-width: 20vw;
}
.choiceButton {
    width: 40%;
}
</style>
