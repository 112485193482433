import {createWebHistory, createRouter} from "vue-router";

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Activities from '../pages/ActivitiesList'
import Activity from '../pages/Activity'
import store from '../store'
import Admin from '../pages/Admin'

export const routes = [
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        alias: '/',
        component: Dashboard
    },
    {
        name: 'activities',
        path: '/activities',
        component: Activities
    },
    {
        path: '/activity/:name',
        component: Activity
    },
    {
        path: '/retry/activity/:name',
        component: Activity,
        params: { retry: true }
    },
    {
        path: '/activity/:name/:difficulty',
        component: Activity
    },
    {
        name: 'ladmin',
        path: '/ladmin',
        component: Admin
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach( (to, from, next) => {
    var isAuthenticated = store.state.auth.authenticated;
    if ( to.name == 'ladmin' )
        return next();
    if ( to.path == '/logout' ) {
        store.dispatch( 'auth/signOut' );
        return next( { name: 'login' } )
    }
    if ( to.name !== 'login' && !isAuthenticated) {
        next( { name: 'login' } )
    } else {
        next();
    }
})

export default router;
